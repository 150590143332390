import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import ClientEmployeeList from "./components/client/employee/EmployeeList";
import AboutUs from "./components/frontend/AboutUs/AboutUs";
import DubaiDetails from "./components/frontend/Dubai/DubaiDetails";
import Home from "./components/frontend/Home/Home";
import BarTending from "./components/frontend/MhAcademy/BarTending";
import Chief from "./components/frontend/MhAcademy/Chief";
import CourseCategoryDetails from "./components/frontend/MhAcademy/CourseCategoryDetails";
import Expensive from "./components/frontend/MhAcademy/Expensive";
import MHAcademy from "./components/frontend/MhAcademy/MHAcademy";
import CoursePaymentError from "./components/frontend/MhAcademy/PaymentError";
import CoursePaymentSuccess from "./components/frontend/MhAcademy/PaymentSuccess";
import School from "./components/frontend/MhAcademy/School";
import StudentForm from "./components/frontend/MhAcademy/StudentForm";
import Waiter from "./components/frontend/MhAcademy/Waiter";
import CancelPolicy from "./components/frontend/Policy/cancel-policy";
import DeliveryPolicy from "./components/frontend/Policy/delivery-policy";
import GoodsService from "./components/frontend/Policy/goods-service";
import GovtPolicy from "./components/frontend/Policy/govt-laws";
import PaymentPolicy from "./components/frontend/Policy/payment-policy";
import PricingPolicy from "./components/frontend/Policy/pricing-policy";
import PrivacyPolicy from "./components/frontend/Policy/privacy-policy";
import RefundPolicy from "./components/frontend/Policy/refund-policy";
import TermsAndConditions from "./components/frontend/Policy/terms-and-conditions";
import About from "./components/frontend/about/About";
import AdminLogin from "./components/frontend/auth/AdminLogin";
import Blog from "./components/frontend/blog/Blog";
import BlogDetails from "./components/frontend/blog/BlogDetails";
import RegisterWelcome from "./components/frontend/profile/RegisterWelcome";
import EmployeeRegister from "./components/frontend/register/EmployeeRegister";
import PrivateRoute from "./routes/PrivateRoute";
// import MyEmployee from "./pages/Client/MyEmployee/MyEmployee.jsx";
import Contact from "./components/frontend/contact/Contact";
import Customer from "./components/frontend/customer/Customer";
import JobDetails from "./components/frontend/employee/jobs/JobDetails";
import Faq from "./components/frontend/faq/Faq";
import ForgotPassword from "./components/frontend/forgotpassword/ForgotPassword";
import InvoiceDetails from "./components/frontend/invoicePayment/InvoiceDetails";
import InvoiceDetails2 from "./components/frontend/invoicePayment/InvoiceDetails2";
import InvoiceDetails3 from "./components/frontend/invoicePayment/InvoicxeDetails3";
// import CreateJobPost from "./components/frontend/job/create/CreateJobPost";
// import JobRequest from "./components/frontend/job/jobRequest/JobRequest";
import CorporateInformation from "./components/frontend/legal/CorporateInformation";
import Privacy from "./components/frontend/legal/Privacy";
import TermsOfUse from "./components/frontend/legal/TermsOfUse";
import Places from "./components/frontend/map/Places";
import MeetTheTeam from "./components/frontend/meetTeam/MeetTheTeam";
import Mission from "./components/frontend/mission/Mission";
import CardValidation from "./components/frontend/paymentGateway/CardValidation";
import Payroll from "./components/frontend/services/Payroll";
import Recruiting from "./components/frontend/services/Recruiting";
import Services from "./components/frontend/services/Services";
import StrategyConsultancy from "./components/frontend/services/StrategyConsultancy";
import VisitingCard from "./components/vc/vc";
import InvoiceLayout from "./layouts/frontend/InvoiceLayout";
import AdminRoutes from "./pages/Admin";
import Login from "./pages/Auth/login/Login";
import CandidateRoutes from "./pages/Candidate";
import ClientRegister from "./pages/Client/ClientRegister";
import ClientMasterLayout from "./pages/Client/Layout/ClientMasterLayout";
import ClientRoutes from "./pages/Client/index.js";
import ViewMediaPost from "./layouts/frontend/dashboard/Notification/ViewMediaPost.jsx";
import GlobalHrAgency from "./pages/Seo/Global-Hr-Agency/Global-Hr-Agency";
import SeoRoutes from "./pages/Seo";
import "./app.scss";
import { pageview } from "./utils/google-tag-manager.js";
import ReactGA from "react-ga4";

ReactGA.initialize("G-9CYG79SDEF", {
  debug_mode: true,
});

function App() {
  const location = useLocation();
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/654d0edd958be55aeaae197c/1heqg44oc";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Append the script to the document body
    document.body.appendChild(script);
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // This function will track the page view, replace with your tracking logic
    const handleRouteChange = (url) => {
      pageview(url, document.title, document.URL);
      // ReactGA.send("pageview");
      // ReactGA.event({
      //   category: "Form",
      //   action: "Submit",
      //   label: "Contact Form",
      //   value: 1,
      // });
    };

    // Trigger pageview when the route changes
    handleRouteChange(location.pathname);

    // Clean up is not necessary here since React Router's useLocation handles route changes automatically.
  }, [location]);

  // Function to clear localStorage item
  const clearLocalStorage = () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("client-recent-viewed");
    localStorage.removeItem("client_tab");
    localStorage.removeItem("twk_token_654d0edd958be55aeaae197c");
  };

  // useEffect(() => {
  //   // Set up the event listener
  //   window.addEventListener("beforeunload", clearLocalStorage);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", clearLocalStorage);
  //   };
  // }, []);

  return (
    <div className="App">
      <ScrollToTop />

      <Routes>
        <Route path="/card-verify" element={<CardValidation />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/employee-register" element={<EmployeeRegister />} />
        <Route path="/client-register" element={<ClientRegister />} />
        <Route path="/forget-Password" element={<ForgotPassword />} />
        <Route path="/register-welcome" element={<RegisterWelcome />} />

        {/* Invoice layout live */}
        <Route path="/" element={<InvoiceLayout />}>
          <Route
            path="payment-invoice-details2/:id"
            element={<InvoiceDetails />}
          />
          <Route
            path="payment-invoice-details3/:id"
            element={<InvoiceDetails2 />}
          />
          <Route
            path="payment-invoice-details/:id"
            element={<InvoiceDetails3 />}
          />
        </Route>
        <Route path="/vc/:name" element={<VisitingCard />} />

        <Route path="/" element={<ClientMasterLayout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/plagit-academy" element={<MHAcademy />} />
          <Route
            path="/course-category/:id"
            element={<CourseCategoryDetails />}
          />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/expensive" element={<Expensive />} />
          <Route path="/bar" element={<BarTending />} />
          <Route path="/school" element={<School />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<RefundPolicy />} />
          <Route path="/pricing-policy" element={<PricingPolicy />} />
          <Route path="/delivery-policy" element={<DeliveryPolicy />} />
          <Route path="/goods-service" element={<GoodsService />} />
          <Route path="/payment-policy" element={<PaymentPolicy />} />
          <Route path="/cancel-policy" element={<CancelPolicy />} />
          <Route path="/govt-laws" element={<GovtPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          <Route
            path="/course-enroll/student-form/:id"
            element={<StudentForm />}
          />
          <Route
            path="/course/payment-success"
            element={<CoursePaymentSuccess />}
          />
          <Route
            path="/course/payment-error"
            element={<CoursePaymentError />}
          />
          <Route path="/chief" element={<Chief />} />
          <Route path="/foh" element={<Waiter />} />
          <Route path="/about" element={<About />} />
          <Route path="/about2/:slug" element={<AboutUs />} />
          {/* <Route path="/about2/" element={<AboutUs />} /> */}
          <Route path="/mh-dubai" element={<DubaiDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />

          <Route path="/customer" element={<Customer />} />
          {/* <Route path='/career' element={<Career />} /> */}
          <Route path="/our-services" element={<Services />} />
          <Route path="/our-mission" element={<Mission />} />
          <Route path="/payroll-services" element={<Payroll />} />
          <Route path="/recruiting-services" element={<Recruiting />} />
          <Route
            path="/strategy-consultancy-services"
            element={<StrategyConsultancy />}
          />
          <Route path="/meet-the-team" element={<MeetTheTeam />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route
            path="/corporate-information"
            element={<CorporateInformation />}
          />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy" element={<Privacy />} />
        </Route>

        <Route path="/social-post-details/:id" element={<ViewMediaPost />} />

        <Route path="/*" element={<ClientRoutes />} />

        <Route path="/global-hr-agency/*" element={<SeoRoutes />} />
        <Route path="/candidate/*" element={<CandidateRoutes />} />

        <Route path="/admin/*" element={<AdminRoutes />} />
        {/* This will match all employee-related routes */}
        {/* Google Map */}
        <Route path="/google-map-view" element={<Places />} />
        {/* admin routes here */}

        {/* for client routes */}
        <Route path="/client" element={<ClientMasterLayout />}>
          <Route
            path="employee-list"
            element={
              <PrivateRoute>
                <ClientEmployeeList />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
