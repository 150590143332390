export function isValidDate(dateString) {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
}

export const minutestoHours = (secs) => {
  const isMinus = secs < 0 ? true : false;
  const sec = isMinus ? secs * -1 : secs;
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = (sec - hours * 3600 - minutes * 60).toFixed(0);
  return (
    (isMinus ? "-" : "") +
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds +
    " H"
  );
};
