import API from "../../../utils/API";

// Function to get notification unread count
export function getNotificationUnreadCount() {
  return API.get(`/notifications/list?readStatus=false`);
}

// Function to get notification list
export function getNotificationList(page) {
  return API.get(`/notifications/list?page=${page}`);
}
