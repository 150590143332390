import { Modal, Select } from "antd";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { FiPhoneOutgoing } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { fetchPositionListForDropdownHandler } from "../../../api/position";
import { ContextApi } from "../../../hooks/ContextApi";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { token } from "../../../utils/authentication";
import {
  getUserId,
  getUserInfo,
  paramsToQueryString,
} from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import ClientProfileCard from "../../../components/frontend/dashboard/ClientProfileCard";
import JobPostSection from "../../../components/frontend/dashboard/JobPostSection";
import PositionCarousel2 from "../../../components/frontend/dashboard/PositionCarousel2";
import PricingCard from "../../../components/frontend/dashboard/PricingCard";
import SocialFeeds from "../../../components/frontend/dashboard/SocialFeeds";
import EmployeeSection from "../../../components/frontend/dashboard/EmployeeSection";
import ClientSearchModal from "../../../components/frontend/searching/ClientSearchModal";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import * as Action from "../../../pages/Client/_redux/clientActions";

const { Option } = Select;

function ClientDashboard2() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const id = jwtDecode?._id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getEmployee, setEmployee] = useState([]);
  const { shouldRefetchShortList, setShouldRefetchShortList, currencyType } =
    useContext(ContextApi);

  const [addShortListData, setAddShortListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shortListLoading, setShortListLoading] = useState(false);
  const [getError, setError] = useState();
  const [positions, setPositions] = useState([]);

  //Set filter data
  const [getName, setName] = useState(undefined);
  const [getExperience, setExperience] = useState(undefined);
  const [getTotalMinHour, setTotalMinHour] = useState(undefined);
  const [getTotalMaxHour, setTotalMaxHour] = useState(undefined);
  const [rangeValue, setRangeValue] = useState([
    getTotalMinHour,
    getTotalMaxHour,
  ]);

  const [rangeUpdateValue, setRangeUpdateValue] = useState([]);

  const [getMinHeight, setMinHeight] = useState(0);
  const [getMaxHeight, setMaxHeight] = useState(300);
  const [heightRangeValue, setHeightRangeValue] = useState([
    getMinHeight,
    getMaxHeight,
  ]);

  const [heightUpdateRangeValue, setHeightUpdateRangeValue] = useState([]);
  const [isPligitPlusModalOpen, setIsPligitPlusModalOpen] = useState(false);

  // const railStyle = { backgroundColor: "#58c8c8" };
  const trackStyle = [{ backgroundColor: "#58c8c8" }];
  const handleStyle = {
    backgroundColor: "#58c8c8",
    borderColor: "#58c8c8",
  };

  const [getPosition, setPosition] = useState(undefined);

  //get filter data from on change
  const [getFilterPosition, setFilterPosition] = useState(undefined);
  const [getFilterExperience, setFilterExperience] = useState(undefined);
  const [getFilterNationality, setNationalityFilter] = useState(undefined);
  const [getDressSize, setDressSize] = useState(undefined);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [jobPostCount, setJobPostCount] = useState(0);
  const [todaysEmployeeCount, setTodaysEmployeeCount] = useState(0);
  const [positionfromApi, setPositionfromApi] = useState([]);
  const [positionfromCarousel, setPositionfromCarousel] = useState(undefined);
  // console.log(`positionfromCarousel`, positionfromCarousel);

  const [sortListIds, setSortListIds] = useState([]);

  // console.log(`getFilterRating`, getFilterRating);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [socialTotal, setSocialTotal] = useState(0);
  const [jobTotal, setJobTotal] = useState(0);
  const [tabs, setTabs] = useState("social");
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const userInfo = getUserInfo();

  const fetchEmployeesData = useCallback(() => {
    // Correctly use useCallback
    const startDateValue = undefined;
    const endDateValue = undefined;
    // const startDateValue = dayjs().format("YYYY-MM-DD");
    // const endDateValue = dayjs().format("YYYY-MM-DD");

    const userId = jwtDecode?._id || userInfo?._id;
    // console.log("fetch-my-employees", userId);
    if (userId) {
      dispatch(
        Action.fetchMyEmployeesList(startDateValue, endDateValue, userId)
      );
    }
  }, [dispatch, jwtDecode?._id, userInfo?._id]);
  useEffect(() => {
    fetchEmployeesData();
  }, [fetchEmployeesData, jwtDecode._id, userInfo._id]);

  const handleTabs = (tab) => {
    localStorage.setItem("client_tab", tab);
    setTabs(tab);
  };

  // Search Box ==============================
  const [isModalSearchOpen, setModalSearchOpen] = useState(false);

  const showSearchModalOpen = () => setModalSearchOpen(true);
  const isModalCancel = () => setModalSearchOpen(false);

  const showProfileModalOpen = () => {
    setProfileModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Make an API call to get the minimum and maximum values
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users/hourly-rate-info`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((response) => {
        // console.log(`response`, response);
        const { min, max } = response.data.result;
        setTotalMinHour(min);
        setTotalMaxHour(max);
        setRangeValue([min, max]);
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  }, []);

  const handleRangeChange = (values) => {
    // console.log(`values`, values);
    setRangeValue(values);
    // You can perform filtering or other actions here based on the selected range values
  };

  const handleHeightRangeChange = (values) => {
    // console.log(`values`, values);
    console.log(`values`, values);
    setHeightRangeValue(values);
  };
  async function fetchEmployees2({
    getName,
    getFilterExperience,
    getFilterNationality,
    getFilterPosition,
    getDressSize,
    rangeUpdateValue,
    currentPage,
    heightUpdateRangeValue,
    positionfromCarousel,
    itemsPerPage = 9,
  }) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users?active=YES&requestType=EMPLOYEE` +
        (getName ? `&searchKeyword=${getName}` : ``) +
        (getFilterPosition !== undefined && getFilterPosition !== "all"
          ? `&positionId=${getFilterPosition}`
          : ``) +
        (getFilterExperience
          ? `&employeeExperience=${getFilterExperience}`
          : ``) +
        (getFilterNationality ? `&nationality=${getFilterNationality}` : ``) +
        (getDressSize ? `&dressSize=${getDressSize}` : ``) +
        (rangeUpdateValue && rangeUpdateValue.length > 0
          ? `&minHourlyRate=${rangeUpdateValue[0]}&maxHourlyRate=${rangeUpdateValue[1]}`
          : ``) +
        (heightUpdateRangeValue && heightUpdateRangeValue.length > 0
          ? `&minHeight=${heightUpdateRangeValue[0]}&maxHeight=${heightUpdateRangeValue[1]}`
          : ``) +
        (positionfromCarousel && positionfromCarousel._id
          ? `&positionId=${positionfromCarousel._id}`
          : ``) +
        `&page=${currentPage}&limit=${itemsPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    return response.data; // Assuming the API response format includes the data we're interested in
  }

  const { data, error, isLoading } = useQuery(
    [
      "fetchEmployees",
      getName,
      getFilterExperience,
      getFilterNationality,
      getFilterPosition,
      getDressSize,
      rangeUpdateValue,
      currentPage,
      heightUpdateRangeValue,
      positionfromCarousel,
    ],
    () =>
      fetchEmployees2({
        getName,
        getFilterExperience,
        getFilterNationality,
        getFilterPosition,
        getDressSize,
        rangeUpdateValue,
        currentPage,
        heightUpdateRangeValue,
        positionfromCarousel,
      })
  );

  // console.log(data, error, isLoading);

  // get all nationality
  const [getNationality, setNationality] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/commons/nationality`)
      .then((res) => {
        // console.log(`res`, res.data.nationalities);
        setNationality(res.data.nationalities);
      });
  }, []);

  const handlePageChange = (newPage) => {
    // console.log(`newPage`, newPage);
    setCurrentPage(newPage);
  };

  const fetchPositionData = useCallback(async () => {
    await fetchPositionListForDropdownHandler().then((res) => {
      setPositions(res?.data?.positions);
    });
  }, []);

  const fetchShortListData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/short-list/list`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    if (res?.status === 201) {
      const idArr = [];
      res.data?.shortList.forEach((emp) => {
        emp?.employeeDetails.forEach((singleEmp) => {
          idArr.push(singleEmp?.employeeDetails?.employeeId);
        });
      });
      setSortListIds(idArr);
    }
  };

  useEffect(() => {
    fetchShortListData();
    fetchPositionData();
    fetchPostListData();
    fetchMyJob();
  }, []);

  const fetchMyJob = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/job?clientId=${jwtDecode?._id}&limit=50&page=1`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (res.data?.status == "success") {
        setJobTotal(res.data?.total);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchPostListData = async () => {
    setLoading(true);
    let endpint = `${process.env.REACT_APP_API_BASE_URL}/social-feed?user=${
      jwtDecode?._id
    }&limit=${10}&page=${1}`;

    await axios
      .get(endpint, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        if (res.data?.status == "success") {
          console.log(res.data);
          setSocialTotal(res.data?.socialFeeds?.total);
        }
      });
    setLoading(false);
  };

  const info = getUserInfo();
  // console.log(`info`, info);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/654d0edd958be55aeaae197c/1heqg44oc";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Append the script to the document body
    if (info.role === "CLIENT") {
      document.body.appendChild(script);
    }
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [info]);
  const fetchInvoiceList = async () => {
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/invoices?clientId=${jwtDecode?._id}&skipLimit=YES`,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      );

      if (responseData && responseData?.data.statusCode === 200) {
        setInvoiceCount(
          responseData?.data?.invoices?.filter((item) => item?.status === "DUE")
            ?.length
        );
        setLoading(false);
      } else if (responseData && responseData?.data.statusCode === 400) {
        setError(responseData.errors);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(true);
      // console.log(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    const params = {
      userType: "CLIENT",
      clientId: getUserId(),
    };
    const query = paramsToQueryString(params);
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/job?${query}`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        })
        .then((res) => {
          setJobPostCount(res?.data?.total);
        });
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }, []);
  // const fecthMyEmployee = async () => {
  //   const query = {
  //     startDate: dayjs().format("YYYY-MM-DD"),
  //     endDate: dayjs().format("YYYY-MM-DD"),
  //     hiredBy: getUserInfo()?._id,
  //   };
  //   const queryToString = paramsToQueryString(query);
  //   const url = `${process.env.REACT_APP_API_BASE_URL}/book-history/client-employee?${queryToString}`;
  //   try {
  //     const responseData = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token()}`,
  //       },
  //     });
  //     if (responseData && responseData?.data.statusCode === 200) {
  //       const data = responseData?.data?.details?.result[0]?.employee;
  //       setTodaysEmployeeCount(data?.length);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  useEffect(() => {
    fetchInvoiceList();
    // fecthMyEmployee();
  }, []);

  useEffect(() => {
    const fetchPositionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/positions?skipLimit=YES`,
          {
            headers: {
              Authorization: `Bearer ${token()}`, // Assuming token() is a function to get the token
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          //  console.log(`data`, data.positions);
          setPositionfromApi(data.positions);
          setLoading(false);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchPositionData();
  }, []);

  const [message, setMessage] = useState("");
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        const conversationId = res.data.details._id;
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((res) => {
            setMessage(res.data.details.count);
          });
      });
  }, []);

  const client_tab =
    localStorage.getItem("client_tab") && localStorage.getItem("client_tab");

  useEffect(() => {
    if (type == "mypost") {
      setTabs("social");
    } else if (client_tab) {
      setTabs(client_tab);
    } else {
      setTabs("employees");
    }
  }, [type, client_tab]);

  const handlePligitPlusCancel = () => {
    setIsPligitPlusModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
        />
        <title>
          Client Dashboard | PLAGIT: Your Gateway to Talent Excellence
        </title>
      </Helmet>

      {/* Dashboard part 1 */}
      <section className="dashboard1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="row g-2">
                <div className="plus col-sm-12 col-md-12 col-lg-6 pb-2">
                  <div className="card">
                    <div
                      className="card-body d-flex justify-content-center"
                      onClick={showProfileModalOpen}
                    >
                      <img
                        src={
                          info.profilePicture
                            ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${info.profilePicture}`
                            : "/assets/client/Client.jpg"
                        }
                        alt="Profile"
                        style={{
                          width: "63px",
                          height: "63px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          border: "3px solid #58c8c8",
                        }}
                      />
                      <div className="ellipsis card-title">
                        {info?.restaurantName?.split(" ")[0]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="row categories_item_wrapper2">
                <div className="col item_col_div">
                  <div
                    className="link_div"
                    onClick={() => {
                      handleTabs("social");
                      navigate("/client-dashboard2");
                      window.location.reload();
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        border: "3px solid #58c8c8",
                        background: "#58c8c81a",
                      }}
                    >
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="/assets/client/dashboard/home.png"
                          alt="home"
                        />
                        <h2 className="cat_name">{t("home")}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" onClick={showSearchModalOpen}>
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="/assets/client/dashboard/search.png"
                          alt="search"
                        />
                        <h2 className="cat_name">{t("search")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/dashboard-history">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="/assets/client/dashboard/dashboard.png"
                          alt="dashboard"
                        />
                        <h2 className="cat_name dashboard-title">
                          {t("dashboard")}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/client-myemployee">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="assets/frontend/images/Dashboardimages/dashboard 1/employe.png"
                          alt="categories image"
                        />
                        <h2 className="cat_name">{t("my_candidates")}</h2>
                      </div>
                      {todaysEmployeeCount > 0 ? (
                        <span
                          className="invoiceCount"
                          style={{
                            padding: invoiceCount <= 9 ? "0px 8px" : "4px 7px",
                          }}
                        >
                          {todaysEmployeeCount}
                        </span>
                      ) : null}
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/create-job">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="assets/frontend/images/Dashboardimages/dashboard 1/createJobPost.png"
                          alt="categories image"
                        />
                        <h2 className="cat_name">{t("create_job_post")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/job-requests">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="assets/frontend/images/Dashboardimages/dashboard 1/jobRequest.png"
                          alt="categories image"
                        />
                        <h2 className="cat_name">{t("job_requests")}</h2>
                      </div>

                      {jobPostCount > 0 ? (
                        <span
                          className="invoiceCount"
                          style={{
                            padding: invoiceCount <= 9 ? "0px 8px" : "4px 7px",
                          }}
                        >
                          {jobPostCount}
                        </span>
                      ) : null}
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/client-payment-history">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src="/assets/client/dashboard/payment.png"
                          alt="payments"
                        />
                        <h2 className="cat_name">{t("payments")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                {/* <div className="col item_col_div">
                                    <div className="link_div" onClick={showModal}>
                                        <div className="card-body">
                                            <div className="item">
                                                <img
                                                    className="cat_icon"
                                                    src={help}
                                                    alt="support"
                                                />
                                                <h2 className="cat_name">{t("support")}</h2>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
   */}

                {/* <div className="plus col-lg-2 col-md-4 col-sm-6">
                                    <div
                                        className="card"
                                        onClick={() => setIsPligitPlusModalOpen(true)}
                                    >
                                        <div className="card-body d-flex justify-content-center">
                                            <div className="d-flex justify-content-center align-items-center flex-column">
                                                <img
                                                    src={plugItPlus}
                                                    className="plus-img"
                                                    alt="plugItPlus"
                                                />
                                                <div className="card-title">{t("plugitPlus")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container mx-auto"
        style={{
          // padding: "20px",
          marginBottom: "20px",
          // backgroundColor: "rgba(255, 255, 255, 0.6)",
          borderRadius: "15px",
        }}
      >
        <PositionCarousel2
          positions={positionfromApi}
          setTabs={handleTabs}
          loading={loading}
          setPositionfromCarousel={setPositionfromCarousel}
        />
      </section>

      <section className="container">
        {/* social or jobs tab like a switch */}

        <div className="social_tab_container">
          <div
            className={`tab_item ${tabs === "social" ? "active-tab" : ""}`}
            onClick={() => {
              handleTabs("social");
              navigate("/client-dashboard2");
            }}
          >
            {t("social_feed")}
          </div>

          <div
            className={`tab_item ${tabs === "jobs" ? "active-tab" : ""}`}
            onClick={() => {
              handleTabs("jobs");
              navigate("/client-dashboard2");
            }}
          >
            {t("job_posts")}
          </div>

          <div
            className={`tab_item ${tabs === "employees" ? "active-tab" : ""}`}
            onClick={() => {
              handleTabs("employees");
              navigate("/client-dashboard2");
            }}
          >
            {t("plagit_candidates")}
          </div>
        </div>

        {tabs === "employees" && <EmployeeSection />}
        {tabs === "jobs" && <JobPostSection />}
        {tabs === "social" && <SocialFeeds />}
      </section>

      <Modal
        title={t("help_support")}
        okButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelText={t("close")}
        cancelButtonProps={{
          style: { backgroundColor: "#58c8c8", color: "white" },
        }}
      >
        <div className="col-lg-12 mb-4">
          {/* <div className="row"></div> */}
          <div className="socialMediaIcons">
            <a
              target="_blank"
              href="https://www.facebook.com/RecruitmentMirkoHospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                alt="image"
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/recruitmentmirkohospitality/"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                alt="image"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/mirko-hospitality/mycompany/?viewAsMember=true"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                alt="image"
              />
            </a>
            <a
              href="https://vm.tiktok.com/ZGJmndX98/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="img-fluid"
                src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                alt="image"
              />
            </a>
          </div>
          <div>
            <div className="d-flex mt-4 ">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("reservation")}</p>
                <h5 style={{ fontSize: "20px", color: "#8e6d45" }}>
                  {t("privacy_notice_des_8_4")}
                </h5>
              </div>
            </div>
            <div className="d-flex mt-4 align-items-center">
              <a href="mailto:info@plagit.com">
                <TfiEmail
                  style={{
                    fontSize: "40px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-3">
                <p className="m-0 p-0">{t("email_info")}</p>
                <h5
                  className="m-0 p-0"
                  style={{ fontSize: "15px", color: "#8e6d45" }}
                >
                  info@plagit.com
                </h5>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex mt-4 align-items-center">
              <Link to="/client-help-support">
                <BsFillChatLeftTextFill
                  style={{
                    color: "#58c8c8",
                    cursor: "pointer",
                    fontSize: "40px",
                  }}
                />
              </Link>
              <div className="mx-3">
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                  className="m-0 p-0"
                >
                  Chat Now -{" "}
                  <span
                    style={{
                      color: "#58c8c8",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {message}
                  </span>
                </p>
                <h5
                  className="m-0 p-0"
                  style={{ fontSize: "20px", color: "#8e6d45" }}
                >
                  Live Chat Now with our team
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        open={isPligitPlusModalOpen}
        onCancel={handlePligitPlusCancel}
        // cancelText={t("close")}
        centered
        footer={null}
        width={350}
        escape={true}
        loading={true}
      >
        <PricingCard />
      </Modal>
      <Modal
        okButtonProps={{ style: { display: "none" } }}
        open={isProfileModalOpen}
        onCancel={() => {
          setProfileModalOpen(false);
        }}
        // centered
        // show left of screen

        footer={null}
        width={400}
        escape={true}
        loading={true}
      >
        <ClientProfileCard
          getSingleClientDetails={info}
          setProfileModalOpen={setProfileModalOpen}
          socialTotal={socialTotal}
          jobTotal={jobTotal}
        />
      </Modal>

      <ClientSearchModal open={isModalSearchOpen} onCancel={isModalCancel} />
    </>
  );
}

export default ClientDashboard2;
