import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { FiPhoneOutgoing } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { io } from "socket.io-client";
import notificationSound from "../../../layouts/frontend/dashboard/Notification/notification-sound.mp3";
import { token } from "../../../utils/authentication";
import { getUserId } from "../../../utils/commonFunctions";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import { responseNotification } from "../../../utils/notifcation";
import Loader from "../../../components/loadar/Loader";
import NewChat from "../../../components/frontend/client/NewChat";
import JobPostSection from "./JobPostSection";
import PricingCard from "../../../components/frontend/dashboard/PricingCard";
import SocialFeeds from "../../../components/frontend/dashboard/SocialFeeds";
import "./EmployeeProfile.css";
import EmployeeProfileCard from "../../../components/frontend/employee/profile/EmployeeProfileCard";
import HistorySection from "../../../components/frontend/employee/profile/HistorySection";
import EmployeeSearchModal from "../../../components/frontend/searching/EmployeeSearchModal";
import { Helmet } from "react-helmet";
import API from "../../../utils/API";
import { useCommon } from "../../../contexts/common-context";
// import NewChat from "../../Client/MyEmployee/NewChat";

const EmployeeProfile = () => {
  const { t } = useTranslation();
  const jwt_decode = jwtTokenDecode();
  const {
    isModalOpen,
    setIsModalOpen,
    setIsPligitPlusModalOpen,
    isPligitPlusModalOpen,
  } = useCommon();
  const id = jwt_decode?._id;
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const [getSingleEmployeeDetails, setSingleEmployeeDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meetData, setMeetData] = useState({});
  const [unavailableDatesFromAPI, setUnavailableDatesFromAPI] = useState([]);
  const [bookedHistory, setBookedHistory] = useState([]);
  const [EmployeeHiredHistory, setEmployeeHiredHistory] = useState([]);
  const [EmployeePaymentHistory, setEmployeePaymentHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [tempUserInfo, setTempUserInfo] = useState({});
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [tabs, setTabs] = useState("social");
  const [location, setLocation] = useState({ lat: null, long: null });
  const [socialTotal, setSocialTotal] = useState(0);

  // Search Box ==============================
  const [isModalSearchOpen, setModalSearchOpen] = useState(false);

  const showSearchModalOpen = () => setModalSearchOpen(true);
  const isModalCancel = () => setModalSearchOpen(false);

  const handleTabs = async (tab) => {
    await localStorage.setItem("employee_tab", tab);
    setTabs(tab);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showProfileModalOpen = () => {
    setProfileModalOpen(true);
    // console.log("Profile Modal Open");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/meet/get-my-meets`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        // console.log(res.data[0]);
        setMeetData(res.data[0]);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    try {
      API.get(
        `/check-in-check-out-histories/today-work-place?currentDate=${moment().format(
          "YYYY-MM-DD"
        )}`
      ).then((res) => {
        setUserInfo({
          _id: res?.data?.result?._id,
          employeeId: res?.data?.result?.restaurantDetails?.hiredBy,
          name: res?.data?.result?.restaurantDetails?.restaurantName,
          address: res?.data?.result?.restaurantDetails?.restaurantAddress,
          lat: res?.data?.result?.restaurantDetails?.lat,
          long: res?.data?.result?.restaurantDetails?.long,
          startTime: res?.data?.result?.startTime,
          endTime: res?.data?.result?.endTime,
        });
        setTempUserInfo({
          _id: res?.data?.result?._id,
          employeeId: res?.data?.result?.restaurantDetails?.hiredBy,
          name: res?.data?.result?.restaurantDetails?.restaurantName,
        });
        const payload = {
          senderId: getUserId(),
          receiverId: res?.data?.result?.restaurantDetails?.hiredBy,
          bookedId: res?.data?.result?._id,
          isAdmin: false,
        };
        API.post(`/conversations/create`, payload).then((res) => {
          const conversationId = res.data.details._id;
          axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
              {
                headers: {
                  Authorization: `Bearer ${token()}`,
                },
              }
            )
            .then((res) => {
              setRestaurantMessage(res.data.details.count);
            });
        });
      });
    } catch (err) {
      console.log(err);
    }
  }, [isModalOpen]);

  // console.log(getSingleEmployeeDetails);

  //Fetch refer person list for dropdown
  const fetchSingleEmployeeData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await API.get(`/users/${id}`);

      setSingleEmployeeDetails(res?.data?.details);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  //Fetch refer person list for dropdown
  const fetchSubscriptionData = useCallback(async () => {
    try {
      const res = await API.get(`/subscription/check-subscription`);

      if (res.data?.status == "success") {
        setIsSubscriber(true);
        setIsPligitPlusModalOpen(false);
      } else {
        setIsSubscriber(false);
      }
    } catch (error) {
      setIsSubscriber(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptionData();
    fetchPostListData();
  }, []);

  useEffect(() => {
    fetchSingleEmployeeData();
  }, [id]);

  const fetchPostListData = async () => {
    setLoading(true);
    let endpint = `/social-feed?user=${jwt_decode?._id}&limit=${10}&page=${1}`;

    await API.get(endpint).then((res) => {
      if (res.data?.status == "success") {
        setSocialTotal(res.data?.socialFeeds?.total);
      }
    });
    setLoading(false);
  };

  const fetchData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/users/working-history/${id}`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      console.log(data);

      // Existing code for unavailableDates
      const unavailableDatesFromAPI = data.result.unavailableDates.map(
        (dateRange) => ({
          from: new Date(dateRange.startDate),
          to: new Date(dateRange.endDate),
        })
      );
      setUnavailableDatesFromAPI(unavailableDatesFromAPI);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  // Function to calculate the total interval count
  const getTotalIntervalCount = (dateRanges) => {
    let totalIntervals = 0;

    dateRanges.forEach((range) => {
      const fromDate = new Date(range.from);
      const toDate = new Date(range.to);

      // Calculate the difference in milliseconds
      const interval = toDate - fromDate;

      // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      const intervalInDays = interval / (24 * 60 * 60 * 1000);

      // Add the interval count to the total
      totalIntervals += intervalInDays + 1; // Adding 1 to include the last day
    });

    return totalIntervals;
  };

  // Get the total interval count
  const totalUnavailable = getTotalIntervalCount(unavailableDatesFromAPI);

  // console.log(totalUnavailable);
  // console.log(unavailableDatesFromAPI);
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/notifications/details`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token()}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log('received data: ', data.details);
  //       setBookedHistory(data.details);
  //     });
  // }, []);
  // console.log(bookedHistory);
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/users/hired-history`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token()}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setEmployeeHiredHistory(data?.bookedHistory);
  //     });
  // }, []);
  // console.log(EmployeeHiredHistory);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_BASE_URL}/employee-invoices?employeeId=${id}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token()}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       console.log(data);
  //       // Count the number of invoices with status "DUE"
  //       const dueCount = data?.invoices.filter(
  //         (invoice) => invoice.status === "DUE"
  //       ).length;
  //       setEmployeePaymentHistory(dueCount);
  //     } catch (error) {
  //       // console.error("Error fetching invoice data:", error);
  //     }

  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [id]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          // setError(error.message);
        }
      );
    } else {
      // setError('Geolocation is not supported by your browser.');
    }
  }, []);

  const [message, setMessage] = useState(0);
  const [restaurantMessage, setRestaurantMessage] = useState(0);
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        const conversationId = res.data.details._id;
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/messages/unread-msg?conversationId=${conversationId}`,
            {
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            }
          )
          .then((res) => {
            setMessage(res.data.details.count);
          });
      });
  }, []);

  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    withCredentials: false,
    transports: ["websocket", "polling", "flashsocket"],
  });
  useEffect(() => {
    socket.on("new_message", (data) => {
      var currentUrl = window.location.href;
      // CHECK IF THE MESSAGE IS FROM THE ADMIN
      if (
        data.message.receiverDetails?.length > 1 &&
        data.message.receiverDetails
          .map((item) => item.receiverId)
          .includes(getUserId()) &&
        !currentUrl.includes("/client-help-support")
      ) {
        const message = `Admin: ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
        return;
      }

      // CHECK IF THE MESSAGE IS FROM THE USER
      if (data.message.receiverDetails[0].receiverId === getUserId()) {
        const message = `${data.message.senderDetails.name} : ${data.message.text}`;
        // responseNotification(message, "success");
        new Audio(notificationSound).play();
      }
    });

    return () => {
      socket.off("new_message");
    };
  }, []);

  useEffect(() => {
    const client_tab =
      localStorage.getItem("employee_tab") &&
      localStorage.getItem("employee_tab");
    if (type == "mypost") {
      setTabs("social");
    }
    if (type == "history") {
      setTabs(type);
    }
  }, [type]);

  let profileCompletedPercentage = 0;
  if (getSingleEmployeeDetails?.firstName && getSingleEmployeeDetails?.lastName)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.positionId) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.gender) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.presentAddress) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.emmergencyContact)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.profilePicture)
    profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.bankName) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.cv) profileCompletedPercentage += 10;
  if (getSingleEmployeeDetails?.countryName) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.higherEducation)
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.dateOfBirth) profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.phoneNumber) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.skills)) profileCompletedPercentage += 5;
  if (_.size(getSingleEmployeeDetails?.languages))
    profileCompletedPercentage += 5;
  if (getSingleEmployeeDetails?.employeeExperience)
    profileCompletedPercentage += 5;

  const expireTime = new Date(meetData?.expiredTime).getTime();
  const currentTime = new Date().getTime();
  const isExpired = currentTime > expireTime;
  // console.log("isExpired: ", isExpired);
  //   if (loading) {
  //     return (
  //       <h1>
  //         <Loader />
  //       </h1>
  //     );
  //   }

  const handlePligitPlusCancel = () => {
    setIsPligitPlusModalOpen(false);
  };

  function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
    const R = 3958.8; // Radius of the Earth in miles
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in miles
    return parseFloat(distance).toFixed(1);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  const profilePicture = jwt_decode?.profilePicture
    ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwt_decode?.profilePicture}`
    : `/assets/Employee.jpg`;

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content=" Access the PLAGIT portal. Log in to connect with top-tier talent, manage job postings, and ensure your company's recruitment success."
        />
        <title>
          Employee Dashboard | PLAGIT: Your Gateway to Talent Excellence
        </title>
      </Helmet>

      <div className="container">
        <section className="mt-3 mb-3">
          {/* <div className={` ${isSticky ? "sticky" : ""}`}> */}
          <div className="row">
            <div className="col-md-3">
              <div className="row g-2">
                <div className="plus col-sm-12 col-md-6 col-lg-6 pb-2">
                  <div className="card">
                    <div
                      className="card-body d-flex justify-content-center"
                      onClick={showProfileModalOpen}
                    >
                      <img
                        src={profilePicture}
                        alt="Profile"
                        style={{
                          width: "63px", // Adjust the width as needed
                          height: "63px", // Adjust the height as needed
                          borderRadius: "50%", // Makes it round
                          objectFit: "cover", // Prevents image from stretching
                          border: "3px solid #58c8c8",
                        }}
                      />

                      <div className="ellipsis card-title">
                        {getSingleEmployeeDetails?.name?.split(" ")[0]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row categories_item_wrapper2">
                <div className="col item_col_div">
                  <div
                    className="link_div"
                    onClick={() => {
                      handleTabs("social");
                      window.location.reload();
                      // navigate("/employee-home");
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        border: "3px solid #58c8c8",
                        background: "#58c8c81a",
                      }}
                    >
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/home.png"}
                          alt="dashboard"
                        />
                        <h2 className="cat_name">{t("home")}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" onClick={showSearchModalOpen}>
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/search.png"}
                          alt="search"
                        />
                        <h2 className="cat_name">{t("search")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/candidate/my-calender">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/calander.png"}
                          alt="calender"
                        />
                        <h2 className="cat_name">{t("my_calender")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/candidate/dashboard">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/dashboard.png"}
                          alt="dashboard"
                        />
                        <h2 className="cat_name dashboard-title">
                          {t("dashboard")}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" to="/candidate/payment-history">
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/payment.png"}
                          alt="payment"
                        />
                        <h2 className="cat_name">{t("payments")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col item_col_div">
                  <Link className="link_div" onClick={showModal}>
                    <div className="card-body">
                      <div className="item">
                        <img
                          className="cat_icon"
                          src={"/assets/employee/help.png"}
                          alt="help"
                        />
                        <h2 className="cat_name">{t("support")}</h2>
                      </div>
                    </div>
                  </Link>
                </div>

                {isSubscriber ? (
                  <div className="col item_col_div">
                    <Link className="link_div" to="/candidate/plagit-plus">
                      <div className="card-body">
                        <div className="item">
                          <img
                            className="cat_icon"
                            src={"/assets/employee/plugitPlus.png"}
                            alt="plugItPlus"
                          />
                          <h2 className="cat_name">{t("plugitPlus")}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div className="col item_col_div">
                    <span
                      className="link_div"
                      onClick={() => {
                        setIsPligitPlusModalOpen(true);
                      }}
                    >
                      <div className="card-body">
                        <div className="item">
                          <img
                            className="cat_icon"
                            src={"/assets/plagitplus.svg"}
                            alt="plugItPlus"
                          />
                          <h2 className="cat_name">{t("plugitPlus")}</h2>
                        </div>
                      </div>
                    </span>
                  </div>
                )}

                {/* <div className="col item_col_div">
                                    <a className="link_div"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => { 
                                            if (isSubscriber) {
                                                navigate("/plagit-plus");
                                            } else {
                                                setIsPligitPlusModalOpen(true);
                                            }
                                        }}
                                    >
                                        <div className="card-body">
                                            <div className="item">
                                                <img
                                                    className="cat_icon"
                                                    src={plugItPlus}
                                                    alt="plugItPlus"
                                                />
                                                <h2 className="cat_name">{t("plugitPlus")}</h2>
                                            </div>
                                        </div>
                                    </a> 
                                </div> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>

        {userInfo && userInfo?.name && (
          <section
            className="container d-flex justify-content-between bg-light border mb-4"
            style={{ borderRadius: "20px", padding: "30px 40px" }}
          >
            <div className="d-flex align-items-center gap-2">
              <img
                src="/assets/home.png"
                alt=""
                width={"22px"}
                height={"22px"}
              />
              <h6 className="m-0 p-0">{userInfo?.name}</h6>
            </div>
            <div className="d-flex align-items-center gap-2">
              <img
                src="/assets/map1.png"
                alt=""
                width={"22px"}
                height={"22px"}
              />
              <p className="m-0 p-0 text-dark">{userInfo?.address}</p>
              <h5 className="m-0 p-0">.</h5>
              <p className="m-0 p-0 text-dark">
                <span className="fw-bolder">
                  {getDistanceFromLatLonInMiles(
                    location.lat,
                    location.long,
                    userInfo?.lat,
                    userInfo?.long
                  )}
                </span>
                Miles away
              </p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <img
                src="/assets/clock.png"
                alt=""
                width={"22px"}
                height={"22px"}
              />
              <p className="m-0 p-0 text-dark">
                {userInfo?.startTime} - {userInfo?.endTime}
              </p>
              <h5 className="m-0 p-0">.</h5>
              <p className="m-0 p-0 text-dark">
                <span className="fw-bolder">
                  {userInfo?.startTime &&
                    userInfo?.endTime &&
                    moment
                      .duration(
                        moment(userInfo?.endTime, "hh:mm A")?.diff(
                          moment(userInfo?.startTime, "hh:mm A")
                        )
                      )
                      .asHours()
                      .toFixed(2)}
                </span>
                Hours
              </p>
            </div>
          </section>
        )}

        <section className="row home_tabs_section">
          <div className="col-md-12">
            <div className="tabs_group_wrap">
              <div
                className={`tab_items ${tabs === "social" ? "active" : ""}`}
                onClick={() => {
                  handleTabs("social");
                  navigate("/candidate/home");
                }}
              >
                Social Feed
              </div>
              <div
                className={`tab_items ${tabs === "jobs" ? "active" : ""}`}
                onClick={() => {
                  handleTabs("jobs");
                  navigate("/candidate/home");
                }}
              >
                Job Posts
              </div>
              <div
                className={`tab_items ${tabs === "history" ? "active" : ""}`}
                onClick={() => {
                  handleTabs("history");
                  navigate("/candidate/home");
                }}
              >
                History
              </div>
            </div>
          </div>

          <div className="col-md-12">
            {tabs === "jobs" && <JobPostSection role="employee" />}
            {tabs === "social" && <SocialFeeds />}
            {tabs === "history" && <HistorySection />}
          </div>
        </section>

        {/* job section <AllJobs /> */}

        <Modal
          title={t("help_support")}
          okButtonProps={{ style: { display: "none" } }}
          open={isModalOpen}
          onCancel={handleCancel}
          cancelText={t("close")}
          cancelButtonProps={{
            style: { backgroundColor: "#58c8c8", color: "white" },
          }}
        >
          <div className="col-lg-12 mb-4">
            {/* <div className="row"></div> */}
            <div className="socialMediaIcons">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61559738663892"
                rel="noreferrer"
              >
                <img
                  className="img-fluid"
                  src="/assets/frontend/images/indexImages/socialMedia/Group 116168.png"
                  alt="image"
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/plagit.ae"
                rel="noreferrer"
              >
                <img
                  className="img-fluid"
                  src="/assets/frontend/images/indexImages/socialMedia/Group 116169.png"
                  alt="image"
                />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/102924386/admin/dashboard"
                rel="noreferrer"
              >
                <img
                  className="img-fluid"
                  src="/assets/frontend/images/indexImages/socialMedia/Group 116170.png"
                  alt="image"
                />
              </a>
              <a
                href="https://www.tiktok.com/@plagitmh"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid"
                  src="/assets/frontend/images/indexImages/socialMedia/Group 116171.png"
                  alt="image"
                />
              </a>
            </div>
            <div>
              {/*<div className="d-flex mt-4">
              <a href="tel:+4407500146699">
                <FiPhoneOutgoing
                  style={{
                    fontSize: "45px",
                    color: "#8e6d45",
                    marginTop: "15px",
                  }}
                />
              </a>
              <div className="mx-5">
                <p>{t("reservation")}</p>
                <h5 style={{ fontSize: "20px", color: "#8e6d45" }}>
                  {t("privacy_notice_des_8_4")}
                </h5>
              </div>
            </div>*/}
              <div className="d-flex align-items-center mt-5">
                <a href="mailto:support@plagit.com">
                  <TfiEmail
                    style={{
                      fontSize: "45px",
                      color: "#8e6d45",
                    }}
                  />
                </a>
                <div className="mx-3">
                  <p className="m-0 p-0">{t("email_info")}</p>
                  <h5
                    className="m-0 p-0"
                    style={{ fontSize: "15px", color: "#8e6d45" }}
                  >
                    support@plagit.com
                  </h5>
                </div>
              </div>
            </div>
            <div>
              {tempUserInfo?.employeeId ? (
                <div className="d-flex align-items-center mt-4">
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleCancel();
                      setOpen(true);
                    }}
                  >
                    <BsFillChatLeftTextFill
                      style={{
                        color: "#58c8c8",
                        fontSize: "40px",
                      }}
                    />
                    <div className="mx-3">
                      <p
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                        className="m-0 p-0"
                      >
                        Chat With {tempUserInfo?.name} - {restaurantMessage}
                      </p>
                      <h5
                        className="m-0 p-0"
                        style={{
                          fontSize: "20px",
                          color: "#8e6d45",
                          textDecoration: "none",
                        }}
                      >
                        Live Chat Now with Restaurant
                      </h5>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <div className="d-flex mt-4">
                <Link to="/client-help-support">
                  <div className="d-flex">
                    <BsFillChatLeftTextFill
                      style={{
                        color: "#58c8c8",
                        cursor: "pointer",
                        fontSize: "45px",
                      }}
                    />
                    <div className="mx-5">
                      <p
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Chat Now - {message}
                      </p>
                      <h5
                        style={{
                          fontSize: "20px",
                          color: "#8e6d45",
                          textDecoration: "none",
                        }}
                      >
                        Live Chat Now with our team
                      </h5>
                    </div>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        </Modal>

        <NewChat
          setOpen={setOpen}
          open={open}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />

        <Modal
          okButtonProps={{ style: { display: "none" } }}
          open={isPligitPlusModalOpen}
          onCancel={handlePligitPlusCancel}
          // cancelText={t("close")}
          centered
          footer={null}
          width={350}
          escape={true}
          loading={true}
        >
          <PricingCard
            fetchSubscriptionData={fetchSubscriptionData}
            setLoading={setLoading}
          />
        </Modal>

        <Modal
          okButtonProps={{ style: { display: "none" } }}
          open={isProfileModalOpen}
          onCancel={() => {
            setProfileModalOpen(false);
          }}
          // centered
          // show left of screen

          footer={null}
          width={400}
          escape={true}
          loading={true}
        >
          <EmployeeProfileCard
            getSingleEmployeeDetails={getSingleEmployeeDetails}
            setProfileModalOpen={setProfileModalOpen}
            socialTotal={socialTotal}
          />
        </Modal>

        <EmployeeSearchModal
          open={isModalSearchOpen}
          onCancel={isModalCancel}
        />
      </div>
    </>
  );
};

export default EmployeeProfile;
