import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-day-picker/dist/style.css";
import "react-phone-input-2/lib/style.css";
import { HelmetProvider } from "react-helmet-async";
import "./assets/css/_main.css";
import "./custom.scss";
import "./Responsive.scss";
import ContextProvider from "./hooks/ContextApi";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "./common/ScrollToTop";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { CommonProvider } from "./contexts/common-context";
const helmetContext = {};
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CommonProvider>
      <PersistGate loading={<div>loader</div>} persistor={persistor}>
        <BrowserRouter>
          <HelmetProvider context={helmetContext}>
            <QueryClientProvider client={queryClient}>
              <ContextProvider>
                <ScrollToTop />
                <App />
              </ContextProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </BrowserRouter>
      </PersistGate>
    </CommonProvider>
  </Provider>
);
