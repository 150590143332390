import * as requestFromServer from "./candidateRequest";
import { callTypes, candidateSlice } from "./candidateSlice";

const { actions } = candidateSlice;

export const getJobList = (limit, page) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .jobList(limit, page)
    .then((response) => {
      if (response.data.status == "success") {
        const { jobs } = response.data;
        dispatch(
          actions.jobListFetched({
            entities: jobs,
            totalCount: response.data?.total,
            totalPages: Math.ceil(response.data.total / limit),
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const getMySocialPostList = (user, limit, page) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .mySocialPostList(user, limit, page)
    .then((response) => {
      if (response.data.status == "success") {
        const { socialFeeds } = response.data;

        console.log("socialFeeds", socialFeeds);
        
        dispatch(
          actions.mySocialPostFetched({
            entities: socialFeeds,
            totalCount: response.data?.total,
            totalPages: Math.ceil(response.data.total / limit),
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};