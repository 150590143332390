import React, { useCallback, useEffect, useState } from "react";
import "./my_profile_style.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as Action from "../_redux/clientActions";
import PostFeedCardMulti from "../../../components/frontend/dashboard/SocialFeed/PostFeedCardMulti";
import CreatePost from "../../../components/Cards/JobPost/CreatePost";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import SkeletonPostCard from "../../../components/Cards/SocialFeeds/SkeletonPostCard";
import {
  FaUser,
  FaBuilding,
  FaMapMarkerAlt,
  FaBriefcase,
} from "react-icons/fa";
import { BsFillArrowLeftCircleFill, BsTelephoneFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function MySocialPost() {
  const dispatch = useDispatch();
  const jwtDecode = jwtTokenDecode();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  //   const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedPost, setSelectedPost] = useState({});
  const { user } = useSelector((state) => state.auth);

  const [posts, setPosts] = useState([]);

  const { postList, myPostList, totalPostPages, postLoading, totalPages } =
    useSelector(
      ({ client }) => ({
        postLoading: client?.listLoading,
        postList: client?.postData.entities ?? [],
        totalPostPages: client?.postData.totalPages,
        myPostList: client?.myPostData?.entities ?? [],
        totalPages: client.myPostData?.totalPages,
      }),
      shallowEqual
    );

  useEffect(() => {
    setPosts(myPostList);
  }, [myPostList]);

  // Fetch posts using Redux
  const fetchPostListData = useCallback(() => {
    dispatch(
      Action.fetchPosts("mypost", pageSize, currentPage, jwtDecode?._id)
    );
  }, [currentPage, dispatch, jwtDecode?._id, pageSize]);

  useEffect(() => {
    fetchPostListData();
  }, [currentPage, fetchPostListData]);

  const [isTabSticky, setIsTabSticky] = useState(false);

  // Function to handle post updates
  const handleUpdatePost = async (updatedPost) => {
    try {
      // Update the local state with the updated post
      setPosts(
        posts.map((post) => (post._id === updatedPost._id ? updatedPost : post))
      );
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };

  const fetchMorePosts = () => {
    const temp = Math.ceil(posts.length / pageSize);
    if (currentPage < totalPages) {
      if (posts.length > 0 && pageSize > 0 && temp !== currentPage) {
        setCurrentPage(temp + 1);
      } else {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } else {
      setHasMore(false);
    }
  };

  // Function to handle post updates
  const handleDeletePost = async (post) => {
    console.log("post~~", post);
    // try {
    //   // Update the local state with the updated post
    //   const allpost = [...posts];
    //   const data = allpost.filter((item) => item?._id != post?._id);
    //   console.log({ data });
    //   setPosts(data);
    // } catch (error) {
    //   console.error("Failed to update post:", error);
    // }
  };

  useEffect(() => {
    if (currentPage <= totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    const handleScrollPage = () => {
      if (window.scrollY > 400) {
        setIsTabSticky(true);
      } else {
        setIsTabSticky(false);
      }
    };

    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, []);

  return (
    <div className="container p-0">
      <div className="my_profile_card">
        <header className="profile_card_header">
          <div className="cover_bg_section">
            <div onClick={() => navigate(-1)} className="mt-3 back_button">
              <BsFillArrowLeftCircleFill
                style={{ color: "#58c8c8", fontSize: "30px" }}
              />{" "}
              Back
            </div>
          </div>
          <div className="container profile_image">
            <div className="images">
              <img
                src={
                  jwtDecode?.profilePicture
                    ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`
                    : "/assets/Employee.jpg"
                }
                alt={jwtDecode?.restaurantName}
              />
            </div>
            <div className="heading_info_box">
              {jwtDecode?.role === "EMPLOYEE" ? (
                <>
                  <h2 className="h2">{jwtDecode?.name}</h2>
                  <div className=" mt-3 user-profile-position">
                    <FaBriefcase
                      style={{
                        color: "#58c8c8",
                      }}
                    />{" "}
                    <p>{jwtDecode?.positionName}</p>
                  </div>
                  <div className="h6 user-profile-countryname">
                    <FaMapMarkerAlt
                      style={{
                        color: "#58c8c8",
                      }}
                    />{" "}
                    <p>{jwtDecode?.countryName}</p>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="mb-2">
                    <FaBuilding /> {jwtDecode?.restaurantName}
                  </h2>
                  <div className="h6 pt-3 user-profile-countryname">
                    <FaMapMarkerAlt
                      style={{
                        color: "#58c8c8",
                      }}
                    />{" "}
                    <p>
                      {jwtDecode?.restaurantAddress}, {jwtDecode?.countryName}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>
      </div>

      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-7 order-lg-1 order-2">
            <InfiniteScroll
              dataLength={posts.length ?? 0}
              next={fetchMorePosts}
              hasMore={hasMore}
              loader={postLoading ? <SkeletonPostCard /> : null}
              endMessage={
                <p style={{ textAlign: "center" }}>You have seen all post.</p>
              }
            >
              {posts.map((item, i) => (
                <PostFeedCardMulti
                  key={i}
                  userName={
                    item?.user?.role === "CLIENT"
                      ? item?.user?.restaurantName
                      : item?.user?.name
                  }
                  userImage={
                    item?.user?.profilePicture &&
                    item?.user?.profilePicture !== "undefined"
                      ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.user?.profilePicture}`
                      : item?.user?.role === "CLIENT"
                      ? "/assets/Client.jpg"
                      : item?.user === user?._id
                      ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${user?.profilePicture}`
                      : "/assets/Employee.jpg"
                  }
                  postTime={item?.createdAt}
                  content={item?.content}
                  postContentImg={item?.media}
                  post={item}
                  handleUpdatePost={handleUpdatePost}
                  setEdit={setEdit}
                  setSelectedPost={setSelectedPost}
                  setCurrentPage={setCurrentPage}
                  handleDeletePosts={handleDeletePost}
                />
              ))}

              {postLoading && (
                <>
                  <SkeletonPostCard />
                  <SkeletonPostCard />
                  <SkeletonPostCard />
                </>
              )}
            </InfiniteScroll>
          </div>

          <div className="col-lg-5 order-lg-2 order-1 mb-4 position-relative">
            <div className={`${isTabSticky ? "sticky_create_post" : ""}`}>
              <CreatePost
                setCurrentPage={setCurrentPage}
                edit={edit}
                selectedPost={selectedPost}
                setSelectedPost={setSelectedPost}
                handleUpdatePost={handleUpdatePost}
                // fetchPostListData={fetchPostListData}
                post={posts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
