
import './PreLoader.css';

const PreLoader = () => {
    return (
        <div className="preloader17" style={{height: "80vh"}}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default PreLoader;
