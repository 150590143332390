import React from "react"; 
import "./HistorySection.css";
import { Tabs } from "antd";
import EmployeeBookedHistory from "../../../../pages/Candidate/BookHistory/EmployeeBookedHistory";
import EmployeeHiredHistory from "../../../../pages/Candidate/HiredHistory/EmployeeHiredHistory";

const tabComponent = [
    {
        key: 1,
        label: "Booked History",
        children: <EmployeeBookedHistory />,
    },
    {
        key: 2,
        label: "Hired History",
        children: <EmployeeHiredHistory />,
    },
];

export default function HistorySection() {
    const onChange = (key) => {
        console.log(key);
    };
    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-md-12 history_tabs_wrapper">
                    <Tabs
                        tabPosition='left'
                        onChange={onChange}
                        // type="card"
                        items={tabComponent.map((item, i) => {
                            const id = String(i + 1);
                            return {
                                label: item.label,
                                key: id,
                                children: item.children,
                            };
                        })}
                    />
                </div>
            </div>
 
        </div>
    );
}
