import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  error: null,
  isCreatePostSuccess: false,
  shortListData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
    shortListIds: [],
  },
  postData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  myEmployeeData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  myPostData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  historyData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  employeePaymentData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  clientPaymentData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  employeeCheckinCheckoutData: {
    entities: [],
    totalCount: 0,
    totalPages: 0,
  },
  job: {
    loading: true,
    totalCount: 0,
    totalPages: 0,
    entities: [],
    myjob: [],
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const clientSlice = createSlice({
  name: "client",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      state.postData = state.postData || {
        entities: [],
        totalCount: 0,
        totalPages: 0,
      };
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    shortlistFetched: (state, action) => {
      // console.log("shortlistFetched action:", action, state);
      state.listLoading = false;
      state.shortlistData = action.payload;
      state.error = null;
    },
    // for dashboard-history route data
    historyListFetched: (state, action) => {
      // console.log("historyListFetched action:", action, state);
      state.listLoading = false;
      state.historyData = action.payload;
      state.error = null;
    },
    // for payment-history route data for employee
    employeePaymentHistoryListFetched: (state, action) => {
      // console.log("historyListFetched action:", action, state);
      state.listLoading = false;
      state.employeePaymentData = action.payload;
      state.error = null;
    },
    // for check in and checkout dashboard
    employeeCheckinCheckoutListFetched: (state, action) => {
      // console.log("historyListFetched action:", action, state);
      state.listLoading = false;
      state.employeeCheckinCheckoutData = action.payload;
      state.error = null;
    },
    // for payment-history route data for client
    clientPaymentHistoryListFetched: (state, action) => {
      // console.log("historyListFetched action:", action, state);
      state.listLoading = false;
      state.clientPaymentData = action.payload;
      state.error = null;
    },
    myEmployeeListFetched: (state, action) => {
      // console.log("myEmployeeListFetched action:", action, state);
      state.listLoading = false;
      state.myEmployeeData = action.payload;
      state.error = null;
    },
    postsFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;

      if (action.payload?.type === "mypost") {
        // Combine the existing entities with the new ones

        let allPosts = [];
        if (state?.myPostData?.entities) {
          allPosts = [...state?.myPostData?.entities];
        }
        allPosts = [...allPosts, ...action.payload.entities];

        // Ensure unique posts by using a Set on post _id
        const uniquePosts = Array.from(
          new Set(allPosts.map((post) => post._id))
        ).map((id) => allPosts.find((post) => post._id === id));
        state.myPostData = {
          entities: uniquePosts,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        };
      } else {
        // Combine the existing entities with the new ones
        const allPosts = [
          ...state.postData.entities,
          ...action.payload.entities,
        ];

        // Ensure unique posts by using a Set on post _id
        const uniquePosts = Array.from(
          new Set(allPosts.map((post) => post._id))
        ).map((id) => allPosts.find((post) => post._id === id));
        state.postData = {
          entities: uniquePosts,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        };
      }
      state.error = null;
    },
    postCreated: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.isCreatePostSuccess = true;

      if (state?.postData?.entities.length) {
        // added to post data
        state.postData.entities = [
          action.payload.post,
          ...state.postData.entities,
        ];
      } else {
        state.postData = {
          entities: [action.payload.post],
          totalCount: 1,
          totalPages: 1,
        };
      }

      // added to my post data
      if (state?.myPostData?.entities.length) {
        state.myPostData.entities = [
          action.payload.post,
          ...state.postData.entities,
        ];
      } else {
        state.myPostData = {
          entities: [action.payload.post],
          totalCount: 1,
          totalPages: 1,
        };
      }
    },
    postCreatedSuccess: (state, action) => {
      state.isCreatePostSuccess = action.payload;
    },
    postUpdated: (state, action) => {
      state.actionsLoading = false;
      state.isCreatePostSuccess = true;
      state.postData.entities = state.postData.entities.map((post) =>
        post._id === action.payload.post._id ? action.payload.post : post
      );
      if (state?.myPostData?.entities) {
        state.myPostData.entities = state.myPostData.entities.map((post) =>
          post._id === action.payload.post._id ? action.payload.post : post
        );
      }
    },
    postDeleted: (state, action) => {
      state.actionsLoading = false;
      state.postData.entities = state.postData.entities.filter(
        (post) => post._id !== action.payload.postId
      );

      if (state?.myPostData?.entities) {
        state.myPostData.entities = state.myPostData.entities.filter(
          (post) => post._id !== action.payload.postId
        );
      }
    },

    // job list
    jobListFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.job.entities = action.payload.entities;
      state.job.totalCount = action.payload.totalCount;
      state.job.totalPages = action.payload.totalPages;
      state.job.loading = false;
      state.error = null;
    },

    // my job list
    myJobListFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.job.myjob = action.payload.entities;
      state.error = null;
    },
  },
});
