import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../../utils/commonFunctions";
import axios from "axios";
import { token } from "../../../utils/authentication";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { io } from "socket.io-client";
import Loader from "../../../components/loadar/Loader";
import "./chat_style.scss";
import { jwtTokenDecode } from "../../../utils/jwtDecode";

export default function ClientChatPage() {
  const userInfo = jwtTokenDecode();
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null); // NEW: separate ref for message container
  const [loading, setLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]); // messages
  const [previousMessages, setPreviousMessages] = useState(false); // previous messages
  const [page, setPage] = useState(1); // page
  const [limit, setLimit] = useState(10); // limit
  const [socketData, setSocketData] = useState(null);

  const [hasScrolledUp, setHasScrolledUp] = useState(false);

  const scrollToBottom = () => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    if (!hasScrolledUp) {
      scrollToBottom();
    }
  }, [chatMessages, hasScrolledUp]);

  const handleScroll = () => {
    if (messagesEndRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
      setHasScrolledUp(scrollTop < scrollHeight - clientHeight);
    }
  };

  const handleSendMessage = () => {
    if (!message) {
      notification.error({ message: "Please enter a message" });
      return;
    }
    setSendMessageLoading(true);
    const payload = {
      senderId: getUserId(),
      conversationId: conversationId,
      dateTime: new Date(),
      text: message,
      senderDetails: {
        name: userInfo?.restaurantName,
        profilePicture: userInfo?.profilePicture,
      },
    };
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.emit("message", payload);
    setSendMessageLoading(false);
    setMessage("");
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/messages/create`, payload, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      })
      .then((res) => {
        setSendMessageLoading(false);
        setMessage("");
      })
      .catch((err) => {
        setSendMessageLoading(false);
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  // get conversation id
  useEffect(() => {
    const payload = {
      senderId: getUserId(), // sender id
      isAdmin: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/conversations/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        setConversationId(res.data.details._id); // set conversation id
      });
  }, []);

  // get messages by conversation id
  useEffect(() => {
    if (conversationId) {
      setPreviousMessages(false);
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/messages?conversationId=${conversationId}&limit=${limit}&page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const descendingMessages = res.data.messages.reverse();
          const modifiedMessages = descendingMessages.map((message) => {
            return {
              myMessage:
                message.senderDetails?.role === "ADMIN" ||
                message.senderDetails?.role === "SUPER_ADMIN"
                  ? false
                  : true,
              senderName: message.senderDetails?.name,
              profileImage: message.senderDetails?.profilePicture,
              message: message.text,
              time: moment(message.dateTime).format("DD MMM YY @ h:mm A"),
            };
          });
          setPreviousMessages(res.data.next);
          setMessages([...modifiedMessages, ...messages]);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, limit, page]);

  // load previous messages
  const loadPreviousMessages = () => {
    if (previousMessages) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      withCredentials: false,
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      console.log("Socket connected");
    });
    socket.on("message", (data) => {
      setSendMessageLoading(false);
      setSocketData(data);
    });
    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    return () => {
      console.log("Socket disconnecting");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (socketData && socketData?.conversationId === conversationId) {
      setMessages([
        ...messages,
        {
          myMessage: socketData.senderId === getUserId(),
          senderName: socketData.senderDetails?.name,
          message: socketData.text,
          profileImage: socketData.senderDetails?.profilePicture,
          time: moment(socketData.dateTime).format("DD MMM YY @ h:mm A"),
        },
      ]);
      setSendMessageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  // console.log("messages", messages);

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-md-8 mx-auto msger">
            <header className="msger-header">
              <div className="msger-header-title">
                <button className="back_preview_btn" onClick={goBack}>
                  <MdArrowBackIos />
                </button>
                {/* <i className="fas fa-comment-alt"></i> */} Admin
              </div>
              {/* <div className="msger-header-options">
                                <span><i className="fas fa-cog"></i></span>
                            </div> */}
            </header>
            <div className="main_chat_wrapper">
              {loading && messages.length === 0 ? (
                <Loader />
              ) : (
                <div
                  className="msger-chat"
                  ref={messagesContainerRef}
                  onScroll={handleScroll}
                >
                  {messages.map((msg, index) => (
                    <>
                      {msg.myMessage ? (
                        <div className="msg right-msg" key={index}>
                          {msg.profileImage ? (
                            <div className="msg-img">
                              <img
                                style={{
                                  objectFit: "cover",
                                  width: "50px",
                                  height: " 50px",
                                  borderRadius: "50px",
                                }}
                                src={`${process.env.REACT_APP_ASSETs_BASE_URL}/${msg.profileImage}`}
                                alt="img"
                              />
                            </div>
                          ) : (
                            <div className="msg-img">
                              <div
                                className="text-center"
                                style={{
                                  width: "50px",
                                  height: " 50px",
                                  borderRadius: "50px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {msg?.senderName &&
                                  msg.senderName.split(" ")[0].slice(0, 3)}
                              </div>
                            </div>
                          )}

                          <div>
                            <div className="msg-info-time">{msg.time}</div>
                            <div className="msg-bubble">
                              <div className="msg-info-name">
                                {msg.senderName}
                              </div>

                              <div className="msg-text">{msg.message}</div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="msg left-msg" key={index}>
                          <div
                            className="msg-img"
                            style={{
                              backgroundImage:
                                "url(https://image.flaticon.com/icons/svg/327/327779.svg)",
                            }}
                          >
                            <img
                              src={
                                msg.profileImage
                                  ? `${process.env.REACT_APP_ASSETs_BASE_URL}/${msg.profileImage}`
                                  : "No"
                              }
                              alt=""
                            />
                          </div>

                          <div>
                            <div className="msg-info-time">{msg.time}</div>
                            <div className="msg-bubble">
                              <div className="msg-info-name">
                                {msg.senderName}
                              </div>
                              <div className="msg-text">{msg.message}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  <div ref={messagesEndRef}></div>{" "}
                  {/* NEW: empty div at the end */}
                </div>
              )}
            </div>

            <Form onFinish={handleSendMessage} className="msger-inputarea">
              <TextArea
                className="msger-input me-2"
                style={{ resize: "none" }}
                rows={1}
                type="textArea"
                placeholder="Type a message"
                value={message} // bind value to state
                onChange={(e) => setMessage(e.target.value)} // update state on change
              />

              <Button
                className="msger-send-btn"
                loading={sendMessageLoading}
                type="primary"
                htmlType="submit"
              >
                Send
              </Button>
            </Form>
          </div>
        </div>
      </section>

      {/* <div style={containerStyle}>
                <div
                    style={{
                        backgroundColor: "white",
                    }}
                >
                    <button style={backButtonStyle} onClick={goBack}>
                        <MdArrowBackIos />
                    </button>
                    <span
                        style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                        }}
                    >
                        Admin
                    </span>
                </div>
                <div
                    style={{
                        textAlign: "center",
                        color: "blue",
                        cursor: previousMessages ? "pointer" : "not-allowed",
                        marginBottom: "10px",
                        backgroundColor: "lightgray",
                    }}
                    onClick={loadPreviousMessages}
                >
                    Load previous messages
                </div>
                {loading && messages.length === 0 ? (
                    <Loader />
                ) : (
                    <div
                        style={innerContainerStyle}
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                    >
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: msg.myMessage ? "flex-end" : "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "10px 0px",
                                    }}
                                >
                                    <div>
                                        <span
                                            style={{
                                                fontSize: "10px",
                                                color: "gray",
                                                margin: "0px 10px",
                                            }}
                                        >
                                            {msg.time}
                                        </span>
                                    </div>
                                    <div
                                        style={msg.myMessage ? clientMessageStyle : adminMessageStyle}
                                    >
                                        {msg.message}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef}></div> 
                    </div>
                )}

                
                <Form layout="inline" onFinish={handleSendMessage} style={formStyle}>
                    <Form.Item style={{ flex: 1 }}>
                        <TextArea
                            style={{ resize: "none" }}
                            rows={4}
                            type="textArea"
                            placeholder="Type a message"
                            value={message}  
                            onChange={(e) => setMessage(e.target.value)}  
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={sendMessageLoading} type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Form.Item>
                </Form>
            </div> */}
    </>
  );
}

// {
//     https://chat.openai.com/c/fc8a426f-a851-4e32-acb9-c2ebdbbfe2d6
// }
