import React, { useEffect, useRef, useState } from "react";
import "./search_modal.scss";
import { Link } from "react-router-dom";

const employeeSearchData = [
  {
    id: 1,
    name: "Dashboard",
    url: "/employee-home",
  },
  {
    id: 2,
    name: "Payment History",
    url: "/employee-payment-history",
  },
  {
    id: 3,
    name: "Profile",
    url: "/employee-profile",
  },
];

if (!localStorage.getItem("employee-recent-viewed")) {
  localStorage.setItem("employee-recent-viewed", JSON.stringify([]));
}

export default function EmployeeSearchModal({ open, onCancel }) {
  const modalRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(employeeSearchData);
  const [recentViewedData, setRecentViewedData] = useState(
    JSON.parse(localStorage.getItem("employee-recent-viewed"))
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onCancel]);

  useEffect(() => {
    const results = employeeSearchData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
  }, [searchTerm, employeeSearchData]);

  return (
    <>
      {open && (
        <>
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
          >
            <div className="modal-dialog search_modal_dialog" ref={modalRef}>
              <div className="modal-content">
                <div className="modal-body search-modal">
                  <div className="form-group search_box">
                    <span className="fa fa-search icon"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="list-group result_div">
                    {searchTerm == "" &&
                      recentViewedData.map((item, i) => (
                        <>
                          <Link
                            onClick={() => {
                              let recentViewed = JSON.parse(
                                localStorage.getItem("employee-recent-viewed")
                              );
                              if (
                                !recentViewed.find((menu) => menu.id == item.id)
                              ) {
                                recentViewed.unshift(item);
                                setRecentViewedData(recentViewed);
                                localStorage.setItem(
                                  "employee-recent-viewed",
                                  JSON.stringify(recentViewed)
                                );
                              }
                            }}
                            key={i}
                            to={item.url}
                            className="list-group-item list-group-item-action"
                          >
                            <i className="fas fa-external-link-alt"></i>
                            <span className="item_name">{item.name}</span>
                          </Link>
                        </>
                      ))}

                    {searchTerm != "" && filteredData.length > 0
                      ? filteredData.map((item) => (
                          <Link
                            onClick={() => {
                              let recentViewed = JSON.parse(
                                localStorage.getItem("employee-recent-viewed")
                              );
                              if (
                                !recentViewed.find((menu) => menu.id == item.id)
                              ) {
                                recentViewed.unshift(item);
                                setRecentViewedData(recentViewed);
                                localStorage.setItem(
                                  "employee-recent-viewed",
                                  JSON.stringify(recentViewed)
                                );
                              }
                            }}
                            key={item.id}
                            to={item.url}
                            className={`list-group-item list-group-item-action`}
                          >
                            <i className="fas fa-external-link-alt"></i>
                            <span className="item_name">{item.name}</span>
                          </Link>
                        ))
                      : searchTerm != "" && (
                          <p className="list-group-item">No results found</p>
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
