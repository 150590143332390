import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { getCurrency } from "../../../utils/static/getCurrency";
import './job_post_style.scss'

export default function JobPostCard({ job, jwtDecode }) {
    return (
        <div className='job_post_card_wrapper'>
            <div className='cardHeader'>
                <img
                    className='icon'
                    src={`${process.env.REACT_APP_ASSETS_FRONTEND_URL}/${job?.positionId?.logo}`}
                    alt={job?.positionId?.name} 
                />
                <h3 className='cardTitle'>{job.positionId?.name}</h3>
            </div>
            <div className='cardBody'>
                <p className='cardText'>
                    <span role="img" aria-label="rate" className='emoji'> 💰 </span>
                    <span style={{ color: "#7B7B7B" }}>Rate:</span>
                    {job.minRatePerHour}{getCurrency(jwtDecode?.countryName)} - {job.maxRatePerHour}{getCurrency(jwtDecode?.countryName)}/hr 
                </p>
                <p className='cardText'> 
                    <span role="img" aria-label="experience" className='emoji'>
                        💼
                    </span>
                    <span style={{ color: "#7B7B7B" }}>Experience:</span>{" "}
                    {job.minExperience} - {job?.maxExperience} Yr.
                </p>
                <p className='cardText'>
                    <span role="img" aria-label="date" className='emoji'>
                        🗓️
                    </span>
                    <span style={{ color: "#7B7B7B" }}>Date:</span>{" "}
                    {moment(job.publishedDate).format("DD-MM-YYYY")} -{" "}
                    {moment(job.endDate).format("DD-MM-YYYY")}
                </p>
            </div>
            <Link
                className='view_details_btn' 
                to={
                    jwtDecode?.role == "CLIENT"
                        ? `/edit-job/${job?._id}`
                        : `/job-details/${job?._id}`
                }
            >
                View Job Details
            </Link>
        </div>
    )
} 
 