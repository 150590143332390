import React, { Fragment, useEffect, useRef, useState } from "react";
import "./notification_style.scss";
import ReactTimeAgo from "react-time-ago";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import CommentComponents from "../../../../components/frontend/dashboard/SocialFeed/CommentComponents";
import { Carousel } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../utils/API";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import ReactPlayer from "react-player";

export default function ViewMediaPost() {
  const { id } = useParams();
  const jwtDecode = jwtTokenDecode();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postDetails, setPostDetails] = useState();
  const carouselRef = useRef(null);
  const [likes, setLikes] = useState([]);
  const [showComments, setShowComments] = useState(false);

  const previewLength = 150;
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const nextSlide = () => {
    carouselRef.current.next();
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  const userName = "Ashiful Islam";

  const postTime = "2024-10-01T09:22:35.078Z";

  const post = "active";

  const userImage =
    "https://mh-user-bucket.s3.amazonaws.com/public/users/profile/QchRIyYzi-compressed-image.jpg";

  const content =
    "We're currently updating our records. Please check back soon, or reach out if you need assistance. Thank you for your patience! We're currently updating our records. Please check back soon, or reach out if you need assistance.";

  const postContentImg = [
    { type: "image", url: "evowaHbbb-blog33.jpg" },
    { type: "image", url: "evowaHbbb-blog33.jpg" },
    { type: "video", url: "RtIT3ZNEp-IMG-9208-1727774438326.mov" },
  ];

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((item) => num >= item.value);
    return item
      ? (num / item.value)
        .toFixed(digits)
        .replace(regexp, "")
        .concat(item.symbol)
      : "0";
  }

  // Function to handle post updates
  const handleUpdatePost = async (updatedPost) => {
    try {
      // Update the local state with the updated post
      setPostDetails(updatedPost);
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };

  const likeUnlike = async () => {
    const isLiked = postDetails?.likes.some(
      (like) => like._id === jwtDecode?._id
    );
    let updatedLikes;

    if (isLiked) {
      // Remove like
      updatedLikes = postDetails?.likes.filter(
        (like) => like._id !== jwtDecode?._id
      );
    } else {
      // Add like
      const user = { _id: jwtDecode?._id, name: jwtDecode?.name }; // Replace with actual user info
      updatedLikes = [...postDetails?.likes, user];
    }

    // Update state
    setLikes(updatedLikes);

    // Create a new post object with updated likes
    const updatedPost = { ...postDetails, likes: updatedLikes };

    setPostDetails(updatedPost);
    await API.post(`/social-feed/like-unlike`, {
      postId: postDetails?._id,
    }).then((res) => { });
  };

  const getPostDetails = async (id) => {
    setLoading(true);
    await API.get(`/social-feed/${id}`)
      .then((res) => {
        if (res.data?.status == "success") {
          setPostDetails(res.data?.socialFeed);
          setLikes(res.data?.socialFeed?.likes);
        }
      })
      .catch((err) => console.error(err));
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getPostDetails(id);
    }
  }, [id]);

  const navigate = useNavigate();

  return (
    <div className="view_media_post_single_page">
      <div className="container py-5">
        <button className="back_to_home_btn"
          onClick={() => navigate(-1)}
        >Back
        </button>

        <div className="row mx-auto justify-content-center">
          {postDetails?.media?.length > 0 && (
            <div className="col-md-8 media_part position-relative p-0">
              {postDetails?.media?.length === 1 ? (
                <Fragment>
                  {postDetails?.media?.map((item, i) => (
                    <div className="w-100">
                      {item.type === "image" ? (
                        <img
                          src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                          className="popup_image_view"
                          alt="post images"
                        />
                      ) : (
                        <video controls className="popup_image_view">
                          <source
                            src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  ))}
                </Fragment>
              ) : (
                <>
                  <LeftOutlined
                    onClick={prevSlide}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "20px",
                      zIndex: 10,
                      fontSize: "22px",
                      color: "#fff",
                      cursor: "pointer",
                      background: "rgba(0, 0, 0, 0.7)",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  />

                  <RightOutlined
                    onClick={nextSlide}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "0",
                      zIndex: 10,
                      fontSize: "22px",
                      color: "#fff",
                      cursor: "pointer",
                      background: "rgba(0, 0, 0, 0.7)",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                  />

                  <Carousel
                    ref={carouselRef}
                    initialSlide={currentSlide}
                    dots={false}
                    afterChange={(index) => setCurrentSlide(index)}
                    adaptiveHeight={true}
                  >
                    {postDetails?.media?.map((item, index) => (
                      <div className="position-relative" key={index}>
                        {item.type === "image" ? (
                          <img
                            src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                            className="popup_image_view"
                            alt="post images"
                          />
                        ) : (
                          <video controls className="popup_image_view">
                            <source
                              src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        <a
                          href={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                          download
                          rel="noreferrer"
                          className="download_media_post"
                        >
                          <i class="fas fa-download"></i>
                        </a>
                      </div>
                    ))}

                    {postDetails?.repost?.media?.map((item, index) => (
                      <div className="position-relative" key={index}>
                        {item.type === "image" ? (
                          <img
                            src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                            className="popup_image_view"
                            alt="post images"
                          />
                        ) : (
                          <video controls className="popup_image_view">
                            <source
                              src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        <a
                          href={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item.url}`}
                          target="_blank"
                          download
                          rel="noreferrer"
                          className="download_media_post"
                        >
                          <i class="fas fa-download"></i>
                        </a>
                      </div>
                    ))}
                  </Carousel>
                </>
              )}
            </div>
          )}
          <div
            className={
              postDetails?.media?.length > 0
                ? `col-md-4 content_part`
                : `col-md-8 content_part py-3`
            }
          >
            <div className="user_info_card">
              <div className="profile_image">
                <img
                  className="post_user_img"
                  src={
                    postDetails?.user?.profilePicture &&
                      postDetails?.user?.profilePicture !== "undefined"
                      ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${postDetails?.user?.profilePicture}`
                      : postDetails?.user?.role === "CLIENT"
                        ? "./assets/Client.jpg"
                        : "./assets/Employee.jpg"
                  }
                  alt="User"
                />
              </div>
              <div className="user_info_content">
                <h2>
                  {postDetails?.user?.role === "CLIENT"
                    ? postDetails?.user?.restaurantName
                    : postDetails?.user?.name}
                </h2>
                <p>
                  <span className="post_time">
                    {postDetails?.active ? (
                      <ReactTimeAgo
                        date={postDetails?.createdAt}
                        locale="en-US"
                      />
                    ) : (
                      "Hold"
                    )}
                  </span>
                </p>
              </div>
            </div>

            <hr style={{ border: "1.21px solid rgba(104, 118, 132, 0.3)" }} />

            <div className="post_content_div">
              {postDetails?.content && postDetails?.content.length > 0 && (
                <>
                  {isExpanded
                    ? postDetails?.content
                    : `${postDetails?.content.slice(0, previewLength)}${postDetails?.content.length > previewLength ? "..." : ""
                    }`}

                  {postDetails?.content.length > previewLength && (
                    <span onClick={toggleExpand} className="expanded_btn">
                      {isExpanded ? "See Less" : "See More"}
                    </span>
                  )}
                </>
              )}
            </div>

            <hr style={{ border: "1.21px solid rgba(104, 118, 132, 0.3)" }} />

            <div className="post_feed_actions">
              <button
                className={
                  likes?.some((item) => item?._id === jwtDecode?._id)
                    ? "like-btn-active"
                    : ""
                }
                onClick={() => {
                  likeUnlike();
                }}
              >
                👍 {nFormatter(likes?.length, 1)} Like
              </button>
              <button
                onClick={() => {
                  setShowComments(!showComments);
                }}
              >
                💬 {nFormatter(postDetails?.comments?.length, 1)}
              </button>
            </div>

            {showComments && (
              <CommentComponents
                comment={postDetails?.comments}
                handleUpdatePost={handleUpdatePost}
                postId={postDetails?._id}
                post={postDetails}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const iconStyle = (position, offset) => ({
  position: "absolute",
  top: "50%",
  [position]: offset,
  zIndex: 10,
  fontSize: "22px",
  color: "#fff",
  cursor: "pointer",
  background: "rgba(0, 0, 0, 0.7)",
  padding: "10px",
  borderRadius: "50%",
});
