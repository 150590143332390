import React from "react";
import ChangeTitle from "../../../components/Seo/ChangeTitle";
import { Container, Row, Col } from "react-bootstrap";

const PartTimeJobRecruitmentConsultancy = () => {
  return (
    <div className="bg-white text-black">
      <ChangeTitle
        title="Part-Time Job Recruitment Consultancy | PLAGIT"
        meta="PLAGIT specializes in part-time job recruitment consultancy for businesses in Dubai, London, and Italy. Find the right staff quickly and efficiently."
      />
      <h1 className="h1 text-center">
        Our Part-Time Staffing Solutions for Dubai, London, and Italy
      </h1>
      <p className="text-black">
        At <strong>PLAGIT</strong>, we truly understand how relevant it is to
        find the right part-time staff for businesses of all sectors. Our
        bespoke part-time recruitment solutions meet the exact needs of
        companies operating in Dubai, London, and Italy, hence assuring you that
        you have the correct team to meet your needs.
      </p>
      <div>
        <h2 className="h2">
          Part-Time Job Recruitment Consultancy - A Job Tailored to You{" "}
        </h2>
        <p className="text-black">
          <strong>PLAGIT</strong> is a well-known recruitment agency within the
          industry, providing temporary and permanent staffing solutions to the
          numerous sectors of the industry. Our network connects professionally
          accomplished and established individuals with top employers in the
          hospitality industry and its related sectors, making sure the placed
          talent is just the right fit.
        </p>
        <h3 className="h3">Here are the sectors we serve:</h3>
        <p className="text-black"></p>
      </div>
      <p className="text-black"></p>
      <p className="text-black"></p>
    </div>
  );
};

export default PartTimeJobRecruitmentConsultancy;
