import { DatePicker, Form, Input, Select, Table } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { token } from "../../../utils/authentication";
import {
  getUserCurrencyByCountry,
  getUserInfo,
} from "../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import { donwloadCSV } from "../../../utils/static/donwloadCSV";
import { ContextApi } from "../../../hooks/ContextApi";
import { minutestoHours } from "../../../utils/date";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as Action from "../_redux/clientActions";
function ClientPaymentHistoryForProfile() {
  const { t } = useTranslation();
  const jwtDecode = jwtTokenDecode();
  const userInfo = getUserInfo();

  const [getEmployee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getError, setError] = useState();
  const [modal, setModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const { currencyType } = useContext(ContextApi);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedClientsData, setselectedClientsData] = useState(null);
  const [pageSize, setpageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  //Set filter data
  const [getName, setName] = useState(undefined);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState(null);

  const { historyPaymentList, totalPages, listLoading, totalCount } =
    useSelector(
      ({ client }) => ({
        listLoading: client?.listLoading,
        historyPaymentList: client?.clientPaymentData?.entities ?? [],
        totalPages: client?.clientPaymentData?.totalPages,
        totalCount: client?.clientPaymentData?.totalCount,
      }),
      shallowEqual
    );

  useEffect(() => {
    const userId = jwtDecode?._id || userInfo?._id;
    if (currentPage && pageSize && userId) {
      dispatch(
        Action.fetchClientPaymentHistoryList(
          userId,
          currentPage,
          pageSize,
          dateFilter,
          getName,
          status
        )
      );
    }
  }, [
    dispatch,
    currentPage,
    pageSize,
    dateFilter,
    getName,
    jwtDecode?._id,
    userInfo?._id,
    status,
  ]);
  useEffect(() => {
    setEmployee(historyPaymentList || []);
    setTotalData(totalCount);
  }, [historyPaymentList, totalCount]);

  // console.log("historyPaymentList~~", historyPaymentList);

  const handleDateFilter = (date, dateString) => {
    setDateFilter(dateString);
    console.log("date", dateString);
  };

  const isEditable = (checkInTime) => {
    const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000); // 12 hours ago
    return new Date(checkInTime) >= twelveHoursAgo;
  };

  const showModal = (_id, data) => {
    setModalVisible(true);
    // console.log(_id);
    setSelectedItemId(data.currentHiredEmployeeId);
    setselectedClientsData(data.checkInCheckOutDetails);
    // console.log("data", data);
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setSelectedItemId(null);
    setselectedClientsData(null);
  };

  // const fetchEmployees = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/check-in-check-out-histories?clientId=${jwtDecode._id}&page=${currentPage}&limit=${pageSize}&status=${status}` +
  //         `${dateFilter ? `&filterDate=${dateFilter}` : ""}` +
  //         `${getName ? `&employeeName=${getName}` : ""}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       }
  //     );

  //     if (res && res?.data.statusCode === 200) {
  //       const checkInCheckOutHistories = res.data.result.map((item) => {
  //         const finalCheckIn =
  //           item?.checkInCheckOutDetails?.clientCheckInTime ||
  //           item?.checkInCheckOutDetails?.checkInTime;
  //         const finalCheckOut =
  //           item?.checkInCheckOutDetails?.clientCheckOutTime ||
  //           item?.checkInCheckOutDetails?.checkOutTime;
  //         const finalBreakTime =
  //           item?.checkInCheckOutDetails?.clientBreakTime ||
  //           item?.checkInCheckOutDetails?.breakTime;
  //         const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditCheckOut = item?.checkInCheckOutDetails
  //           ?.clientCheckOutTime
  //           ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             ) ===
  //             dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
  //               "YYYY-MM-DD HH:mm:ss"
  //             )
  //             ? false
  //             : true
  //           : false;
  //         const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
  //           ? item?.checkInCheckOutDetails?.breakTime ===
  //             item?.checkInCheckOutDetails?.clientBreakTime
  //             ? false
  //             : true
  //           : false;
  //         console.log(item?.checkInCheckOutDetails?.checkInTime);
  //         return {
  //           date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
  //           id: item?._id,
  //           country: item?.restaurantDetails?.countryName,
  //           restaurantName: item?.restaurantDetails?.restaurantName,
  //           employeeName: item?.employeeDetails?.name,
  //           position: item?.employeeDetails?.positionName,
  //           contractorHourlyRate: item?.employeeDetails?.contractorHourlyRate,
  //           restaurantRate:
  //             item?.employeeDetails?.hourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           customerRate:
  //             item?.employeeDetails?.contractorHourlyRate +
  //             " " +
  //             getUserCurrencyByCountry(item?.restaurantDetails?.countryName) +
  //             "/Hour",
  //           checkIn: item?.checkInCheckOutDetails?.checkInTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalCheckIn: finalCheckIn
  //             ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckIn: isEditCheckIn,

  //           checkOut: item?.checkInCheckOutDetails?.checkOutTime
  //             ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
  //                 .format("hh:mm:ss A")
  //                 .toString()
  //             : null,
  //           finalcheckOut: finalCheckOut
  //             ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
  //             : null,
  //           isEditCheckOut: isEditCheckOut,

  //           break: item?.checkInCheckOutDetails?.breakTime,
  //           finalBreakTime: finalBreakTime ? finalBreakTime : 0,
  //           isEditBreakTime: isEditBreakTime,

  //           totalHours: finalCheckOut
  //             ? minutestoHours(
  //                 dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60
  //               )
  //             : null,

  //           totalAmount: finalCheckOut
  //             ? (
  //                 (dayjs(finalCheckOut)
  //                   .diff(dayjs(finalCheckIn), "seconds", true)
  //                   .toFixed(0) -
  //                   finalBreakTime * 60) *
  //                 (item?.employeeDetails?.hourlyRate / 3600)
  //               ).toFixed(2) +
  //               " " +
  //               getUserCurrencyByCountry(item?.restaurantDetails?.countryName)
  //             : null,
  //           clientComment: item?.checkInCheckOutDetails?.clientComment,
  //           vat: item?.vat,
  //           vatAmount: item?.vatAmount,
  //           totalAmountWithVat: item?.totalAmount,
  //           employeeAmount: item?.employeeAmount,
  //           status: item?.status,
  //           workedHour: item?.workedHour,
  //           mainItem: item,
  //         };
  //       });

  //       setEmployee(checkInCheckOutHistories || []);
  //       setTotalData(res.data.total);
  //       setLoading(false);
  //     } else if (res && res?.data.statusCode === 400) {
  //       setError(res.errors);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setError(error);
  //     setLoading(true);
  //     console.log(error);
  //   }
  // }, [currentPage, pageSize, dateFilter, getName, status]);

  // useEffect(() => {
  //   fetchEmployees();
  // }, [fetchEmployees]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const myParam = urlParams.get("page");
  //   const myParam2 = urlParams.get("limit");
  //   // console.log(myParam, myParam2);
  //   if (myParam === null || myParam2 === null) {
  //     navigate(`/employee-payment-history?page=1&limit=10`);
  //     window.location.reload();
  //   }
  //   setpageSize(myParam2);
  //   setCurrentPage(myParam);
  // }, [navigate]);

  const handleExportData = () => {
    const excelData = getEmployee.map((item) => {
      return {
        Date: item?.date,
        Country: item?.country,
        RestaurantName: item?.restaurantName,
        EmployeeName: item?.employeeName,
        Position: item?.position,
        RestaurantRate: item?.restaurantRate,
        CustomerRate: item?.customerRate,
        CheckIn: item?.finalCheckIn,
        CheckOut: item?.finalcheckOut,
        Break: item?.finalBreakTime,
        TotalHours: item?.totalHours,
        TotalAmount: item?.totalAmount,
        ClientComment: item?.clientComment,
      };
    });
    donwloadCSV(excelData, "check-in-check-out-histories");
  };
  const dynamicInvoiceLinkGenerator = (country) => {
    console.log(country);
    if (country === "United Kingdom") {
      return "payment-invoice-details";
    } else if (country === "United Arab Emirates") {
      return "payment-invoice-details2";
    } else {
      return "payment-invoice-details3";
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // render upto totalData
      render: (value, record, index) => {
        return (
          <span>
            {(currentPage - 1) * pageSize + (index + 1)}
            {/* {index + 1} */}
          </span>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "date",
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: t("contractor"),
      dataIndex: "restaurantName",
    },
    {
      title: t("position"),
      dataIndex: "position",
      // sorter: (a, b) => a.active.length - b.active.length,
    },
    {
      title: t("contract_per_hour_rate"),
      dataIndex: "contractorHourlyRate",
      render: (contractorHourlyRate) => {
        return `${currencyType}${contractorHourlyRate?.toFixed(2)}`;
      },
    },

    {
      title: t("check_in"),
      dataIndex: "checkIn",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckIn && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkIn}
              </div>
            )}
            <div
              style={
                record?.isEditCheckIn
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalCheckIn}
            </div>
          </div>
        );
      },
    },
    {
      title: t("check_out"),
      dataIndex: "checkOut",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditCheckOut && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.checkOut}
              </div>
            )}
            <div
              style={
                record?.isEditCheckOut
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalcheckOut}
            </div>
          </div>
        );
      },
    },

    {
      title: t("break"),
      dataIndex: "break",
      render: (text, record) => {
        return (
          <div>
            {record?.isEditBreakTime && (
              <div
                style={{
                  textDecoration: "line-through",
                  color: "red",
                }}
              >
                {record?.break} min
              </div>
            )}
            <div
              style={
                record?.isEditBreakTime
                  ? { color: "#58c8c8", fontWeight: "bold" }
                  : null
              }
            >
              {record?.finalBreakTime} min
            </div>
          </div>
        );
      },
    },
    {
      title: t("total_hours"),
      dataIndex: "workedHour",
    },
    {
      title: t("total_amount"),
      dataIndex: "employeeAmount",
      render: (employeeAmount) => {
        return `${currencyType}${employeeAmount?.toFixed(2)}`;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text, record) => {
        return (
          <div>
            {record?.status === "PAID" ? (
              <div
                style={{
                  color: "green",
                }}
              >
                {record?.status}
              </div>
            ) : (
              <div
                style={{
                  color: "red",
                }}
              >
                {record?.status}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("client_comment"),
      dataIndex: "clientComment",
    },
    /* {
      title: t('action'),
      dataIndex: "_id",
      render: (_id, record) => {
        return isEditable(
          record?.mainItem?.checkInCheckOutDetails?.checkInTime
        ) ? (
          <div className="text-center">
            <Button
              className="InnerTableButton"
              onClick={() => {
                // showModal(_id, record);
                console.log("record", record);
                setModal(true);
                form.setFieldsValue({
                  _id: record?.mainItem?.currentHiredEmployeeId,
                  checkInTime:
                    record?.mainItem?.checkInCheckOutDetails
                      ?.clientCheckInTime ||
                    record?.mainItem?.checkInCheckOutDetails?.checkInTime
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails
                            ?.clientCheckInTime ||
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkInTime
                        )
                      : null,
                  checkOutTime:
                    record?.mainItem?.checkInCheckOutDetails
                      ?.clientCheckOutTime ||
                    record?.mainItem?.checkInCheckOutDetails?.checkOutTime
                      ? dayjs(
                          record?.mainItem?.checkInCheckOutDetails
                            ?.clientCheckOutTime ||
                            record?.mainItem?.checkInCheckOutDetails
                              ?.checkOutTime
                        )
                      : null,
                  breakTime:
                    record?.mainItem?.checkInCheckOutDetails?.clientBreakTime ||
                    record?.mainItem?.checkInCheckOutDetails?.breakTime
                      ? record?.mainItem?.checkInCheckOutDetails
                          ?.clientBreakTime ||
                        record?.mainItem?.checkInCheckOutDetails?.breakTime
                      : null,
                  clientComment: record?.mainItem?.checkInCheckOutDetails
                    ?.clientComment
                    ? record?.mainItem?.checkInCheckOutDetails?.clientComment
                    : null,
                });
              }}
            >
              Update
            </Button>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip title="Can't edit after 12 hours">
              <span style={{ color: "red" }}>
                <FcInfo />
              </span>
            </Tooltip>
          </div>
        );
      },
    }, */
  ];
  const [form] = Form.useForm();
  return (
    <div>
      {/* Inner Dashboard Search Part Start */}
      <section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/client-dashboard">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src="assets/employee/payment.png"
                  className="img-fluid"
                  alt="paymentImage"
                />
                <span className="innerDashSearchItemsSpan">
                  {t("payment_history")}
                </span>
                <div
                  className="d-flex"
                  style={{
                    width: "350px",
                    marginLeft: "10px",
                    // border: "1px solid #58c8c8",
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={t("select_date")}
                    size="large"
                    onChange={handleDateFilter}
                  />
                  {/* radio button PAID and DUE  */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <Select
                      defaultValue=""
                      style={{ width: 120 }}
                      size="large"
                      onChange={(value) => {
                        setStatus(value);
                      }}
                    >
                      <Select.Option value="">ALL</Select.Option>
                      <Select.Option value="PAID">PAID</Select.Option>
                      <Select.Option value="DUE">DUE</Select.Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="InnnerDashboardTable">
        <div className="container">
          <Table
            columns={columns}
            dataSource={getEmployee}
            loading={listLoading}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: Number(pageSize),
              current: Number(currentPage),
              showSizeChanger: false,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setpageSize(pageSize);
                navigate(`?page=${page}&limit=${pageSize}`);
              },
              total: totalData,
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default ClientPaymentHistoryForProfile;
