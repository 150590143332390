import React from "react";
import { Route, Routes } from "react-router-dom";

import ClientDashboard from "./Dashboard/ClientDashboard";
import ClientDashboard2 from "./Dashboard/ClientDashboard2";
import ChangeSetting from "../../components/frontend/profile/setting";
import MyEmployee from "./MyEmployee/MyEmployee";
import ShortList from "./ShortList";
import PaymentError from "./PaymentError";
import PaymentSuccess from "./PaymentSuccess";
import PaymentInvoice from "./PaymentInvoice";
import CreateJobPost from "./Job/CreateJobPost";
import JobRequest from "./Job/JobRequest";
import ClientChatPage from "./Chat/ClientChat";
import ClientPaymentHistoryForProfile from "./ClientPaymentHistoryForProfile";
import ClientHomeDashboard from "./ClientHomeDashboard";
import Profile from "../../components/frontend/profile/profile";
import Livelocation from "../../components/frontend/client/LiveLocation/Livelocation";
import CheckInCheckOut from "../../components/frontend/client/CheckInCheckOut";
import EmployeeViewDetails from "../../components/frontend/dashboard/EmployeeViewDetails";
import SuccessfullMessage from "../../components/frontend/shortList/SuccessfullMessage";
import Translate from "../../components/frontend/profile/translate";
import HireEmployeeCalender from "../../components/frontend/calender/HireEmployeeCalender";
import BookFromCalender from "../../components/frontend/calender/BookFromCalender";
import EmployeeCalendarView from "../../components/frontend/calender/ClientCalender";
import DashboardLayout from "../../layouts/frontend/dashboard/DashboardLayout";
import ClientProfile from "./ClientProfile/ClientProfile";
import ViewMediaPost from "../../layouts/frontend/dashboard/Notification/ViewMediaPost";
import MySocialPost from "./ClientProfile/MySocialPost";
import UserProfile from "./UserProfile/UserProfile";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index path="client-dashboard" element={<ClientDashboard />} />
        <Route index path="client-profile" element={<ClientProfile />} />

        <Route index path="client-dashboard2" element={<ClientDashboard2 />} />
        <Route index path="setting" element={<ChangeSetting />} />
        <Route index path="profile" element={<Profile />} />
        <Route index path="profile/:id" element={<UserProfile />} />
        <Route path="client-myemployee" element={<MyEmployee />} />
        <Route path="my-social-post" element={<MySocialPost />} />
        <Route
          path="client-myemployee/live-location/:employee"
          element={<Livelocation />}
        />
        <Route path="dashboard-history" element={<CheckInCheckOut />} />
        <Route
          path="employee-view-details/:id"
          element={<EmployeeViewDetails />}
        />
        <Route path="short-list" element={<ShortList />} />
        <Route path="success-message" element={<SuccessfullMessage />} />

        <Route path="invoice/payment-error" element={<PaymentError />} />
        <Route path="invoice/payment-success" element={<PaymentSuccess />} />
        <Route path="payment-invoice" element={<PaymentInvoice />} />
        <Route path="translate" element={<Translate />} />
        <Route path="create-job" element={<CreateJobPost />} />
        <Route path="edit-job/:id" element={<CreateJobPost />} />
        <Route path="job-requests" element={<JobRequest />} />

        <Route path="/hiring-calender/:id" element={<HireEmployeeCalender />} />

        <Route path="/booknow/:id" element={<BookFromCalender />} />
        <Route path="/view-calender/:id" element={<EmployeeCalendarView />} />

        <Route path="/client-help-support" element={<ClientChatPage />} />
        <Route
          path="/client-payment-history"
          element={<ClientPaymentHistoryForProfile />}
        />
        <Route
          path="/client-home-dashboard"
          element={<ClientHomeDashboard />}
        />
      </Route>
    </Routes>
  );
};

export default ClientRoutes;
