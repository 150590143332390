import React, { useEffect, useState } from "react";
import { BiCamera } from "react-icons/bi";
import { token } from "../../../utils/authentication";
import axios from "axios";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import default_profile from "../../../assets/images/Client.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../pages/Client/_redux/clientActions";
import { clientSlice } from "../../../pages/Client/_redux/clientSlice";

const { actions } = clientSlice;
export function CreatePostCard({
    setCurrentPage,
    edit,
    selectedPost,
    setSelectedPost,
    handleUpdatePost,
    post,
    fetchPostListData,
}) {
    const jwtDecode = jwtTokenDecode();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [content, setContent] = useState("");
    const [files, setFiles] = useState([]);
    const [postCreateLoading, setPostCreateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    console.log("user~~", user);
    const { actionLoading, isCreatePostSuccess } = useSelector(({ client }) => ({
        actionLoading: client.actionsLoading,
        isCreatePostSuccess: client.isCreatePostSuccess,
    }));

    useEffect(() => {
        if (isCreatePostSuccess) {
            toast.success("Post Uploaded");
            setSelectedPost();
            setContent("");
            setFiles([]);
            setSelectedFiles([]);
            dispatch(actions.postCreatedSuccess(false));
        }
    }, [dispatch, isCreatePostSuccess, setSelectedPost]);

    console.log("isCreatePostSuccess~~", isCreatePostSuccess);

    function getFileType(file) {
        if (!file) {
            return "unknown";
        }

        const fileType = file.type;

        if (fileType.startsWith("image/")) {
            return "image";
        } else if (fileType.startsWith("video/")) {
            return "video";
        }

        return "unknown";
    }

    const handleUpload = async (e) => {
        setLoading(true);
        const file = e.target.files[0];

        if (!file) {
            alert("No file selected.");
            return;
        }

        setSelectedFiles((prev) => [...prev, file]);

        const formData = new FormData();
        formData.append("file", file);

        await axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/commons/upload-file`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token()}`,
                    },
                }
            )
            .then((res) => {
                if (res.data?.status == "success") {
                    setFiles((prev) => [
                        ...prev,
                        { url: res.data?.data?.file, type: getFileType(file) },
                    ]);
                } else {
                    toast.error("Invalid File Type");
                }
            })
            .catch((err) => toast.error("Invalid File Type"));

        setLoading(false);
    };

    const handleOnSubmitPost = async () => {
        console.log("handleOnSubmitPost");
        setPostCreateLoading(true);
        if (selectedPost && Object.keys(selectedPost).length > 0) {
            // update post
            dispatch(Actions.updatePost(selectedPost?._id, content, files));

            // await axios
            //   .put(
            //     `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${selectedPost?._id}`,
            //     {
            //       content: content,
            //       media: files,
            //     },
            //     {
            //       headers: {
            //         Authorization: `Bearer ${token()}`,
            //       },
            //     }
            //   )
            //   .then(async (res) => {
            //     if (res.data?.status == "success") {
            //       // await fetchPostListData();
            //       await toast.success("Post Updated");
            //       // const updatedPost = {
            //       //   ...post,
            //       //   content: res.data?.socialFeed?.content,
            //       //   media: res.data?.socialFeed?.media,
            //       // };

            //       // handleUpdatePost(updatedPost);
            //       await setSelectedPost();
            //       await setContent("");
            //       await setFiles([]);
            //       await setSelectedFiles([]);
            //       // await setCurrentPage(1);
            //     }
            //   })
            //   .catch((err) => {
            //     console.log("upload~~", err);
            //     toast.error("Invalid File Type");
            //   });
        } else {
            // create a new post
            dispatch(Actions.createPost(content, files, user));

            // await axios
            //   .post(
            //     `${process.env.REACT_APP_API_BASE_URL}/social-feed/create`,
            //     {
            //       content: content,
            //       media: files,
            //     },
            //     {
            //       headers: {
            //         Authorization: `Bearer ${token()}`,
            //       },
            //     }
            //   )
            //   .then(async (res) => {
            //     console.log("response photo~~", res);
            //     if (res.data?.status == "success") {
            //       await setCurrentPage(1);
            //       // await fetchPostListData();
            //       await toast.success("Post Uploaded");
            //       await setSelectedPost();
            //       await setContent("");
            //       await setFiles([]);
            //       await setSelectedFiles([]);
            //     }
            //   })
            //   .catch((err) => {
            //     console.log("upload~~", err);
            //     toast.error("Invalid File Type");
            //   });
        }
        setPostCreateLoading(false);
    };

    useEffect(() => {
        if (selectedPost && Object.keys(selectedPost).length > 0) {
            setContent(selectedPost?.content);
            setFiles(selectedPost?.media);
        } else {
            setContent("");
            setFiles([]);
        }
    }, [selectedPost]);
    return (
        <div style={styles.createPostCard}>
            <div style={styles.createPostHeader}>
                {jwtDecode?.profilePicture ? (
                    <img
                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
                        alt={
                            jwtDecode?.role == "CLIENT"
                                ? jwtDecode?.restaurantName
                                : jwtDecode?.name
                        }
                        style={styles.userImage}
                    />
                ) : (
                    <img
                        src={default_profile}
                        alt={
                            jwtDecode?.role == "CLIENT"
                                ? jwtDecode?.restaurantName
                                : jwtDecode?.name
                        }
                        style={styles.userImage}
                    />
                )}

                <span>
                    {jwtDecode?.role == "CLIENT"
                        ? jwtDecode?.restaurantName
                        : jwtDecode?.name}
                </span>
            </div>
            <textarea
                style={styles.textarea}
                placeholder="Type here..."
                onChange={(e) => setContent(e.target.value)}
                value={content}
            ></textarea>
            <div style={styles.createPostActions}>
                <ul className="list-group mb-3">
                    {files.map((file, index) => (
                        <li
                            style={{ fontSize: "12px", position: "relative" }}
                            key={index}
                            className="list-group-item d-flex align-items-center gap-2"
                        >
                            <div>
                                {file.type === "image" && file.type !== 'undefined' ? (
                                    <img
                                        src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${file.url}`}
                                        alt=""
                                        width={"30px"}
                                        height={"30px"}
                                    />
                                ) : (
                                    "📹"
                                )}
                            </div>{" "}
                            <div>{file.url}</div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: 0,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    const allfile = [...files];
                                    const upFile = allfile?.filter(
                                        (item) => item?.url != file.url
                                    );
                                    setFiles(upFile);
                                }}
                            >
                                x
                            </div>
                        </li>
                    ))}{" "}
                </ul>
                {files.length <= 5 && (
                    <label style={styles.uploadButton}>
                        <BiCamera
                            style={{
                                marginRight: "10px",
                                fontSize: "20px",
                            }}
                        />
                        {loading ? "Uploading..." : "Upload Photo / Video"}
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleUpload(e)}
                            disabled={loading}
                            accept="image/*,video/*" // Allow image and video files
                        />
                    </label>
                )}

                {(content || files.length > 0) && (
                    <button
                        style={styles.publishButton}
                        type="button"
                        onClick={handleOnSubmitPost}
                        disabled={loading}
                    >
                        {selectedPost && Object.keys(selectedPost).length > 0
                            ? actionLoading
                                ? "Updating"
                                : "Update Now"
                            : actionLoading
                                ? "Publishing.."
                                : "Publish Now"}
                    </button>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        // padding: "20px",
        margin: "auto",
        border: "1px solid #A6A6A6",
        borderRadius: "15px",
        marginBottom: "20px",
        marginTop: "20px",
    },
    leftSection: {
        flex: 2,
        marginRight: "20px",
        padding: "40px",
    },
    rightSection: {
        flex: 1,
        padding: "40px",
    },
    feedCard: {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "15px",
        marginBottom: "20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    feedHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
    },
    userImage: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        marginRight: "10px",
    },
    timeText: {
        color: "#888",
        fontSize: "14px",
    },
    moreButton: {
        marginLeft: "auto",
        background: "none",
        border: "none",
        cursor: "pointer",
    },
    feedContent: {
        marginBottom: "10px",
    },
    contentImage: {
        width: "100%",
        borderRadius: "10px",
    },
    feedActions: {
        display: "flex",
        justifyContent: "space-around",
        color: "#888",
    },
    createPostCard: {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    createPostHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    textarea: {
        width: "100%",
        height: "250px",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid #ccc",
        marginBottom: "15px",
        resize: "none",
    },
    createPostActions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    uploadButton: {
        // backgroundColor: "#f0f0f0",
        background: "transparent",
        color: "#58C8C8",
        borderRadius: "10px",
        padding: "10px 15px",
        cursor: "pointer",
        marginBottom: "10px",
        border: "1px solid #58C8C8",
    },
    publishButton: {
        background: "linear-gradient(to right, #58C8C8, #3F8E9B)",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "10px 15px",
        cursor: "pointer",
    },
};
