import moment from "moment";
import { useState } from "react";
import userImage from "./images/user.jpg";
import { IoMdSend } from "react-icons/io";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import { MdCancel } from "react-icons/md";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { jwtTokenDecode } from "../../../../utils/jwtDecode";
import axios from "axios";
import { token } from "../../../../utils/authentication";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

function Comment({ comment, depth = 0, onReply, jwtDecode, submitting }) {
  const [replyValue, setReplyValue] = useState("");
  const [showReplyBox, setShowReplyBox] = useState(false);

  // const nestedComments = (comment.children || [])
  //   ?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
  //   .map((comment) => {
  //     return (
  //       <Comment
  //         key={comment._id}
  //         comment={comment}
  //         depth={depth + 1}
  //         onReply={onReply}
  //         jwtDecode={jwtDecode}
  //       />
  //     );
  //   });

  // fixed 0 read only issue
  const nestedComments = [...(comment.children || [])] // Create a shallow copy
    ?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
    .map((comment) => {
      return (
        <Comment
          key={comment._id}
          comment={comment}
          depth={depth + 1}
          onReply={onReply}
          jwtDecode={jwtDecode}
        />
      );
    });

  const handleReply = (e) => {
    e.preventDefault();
    if (!replyValue) return;

    onReply(comment._id, replyValue, setReplyValue);
    setReplyValue("");
    setShowReplyBox(false);
  };

  return (
    <div style={{ marginLeft: `${depth * 20}px`, marginTop: "10px" }}>
      <li className="list-group">
        <div className="d-flex align-items-start">
          <img
            src={
              comment?.user?.profilePicture
                ? `https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${comment?.user?.profilePicture}`
                : comment?.user?.role == "CLIENT"
                ? "/assets/Client.jpg"
                : "/assets/Employee.jpg"
            }
            alt="Avatar"
            className="rounded-circle me-3"
            width="50"
            height="50"
          />
          <div>
            <div className="d-flex gap-3">
              <h6>
                {comment.user?.role == "CLIENT"
                  ? comment.user?.restaurantName
                  : comment.user?.name}
              </h6>
              {" • "}
              <small className="text-muted">
                <ReactTimeAgo date={comment?.createdAt} locale="en-US" />
              </small>
            </div>
            <p className="mb-1">{comment.text}</p>
            {depth < 1 && (
              <>
                <p onClick={() => setShowReplyBox(!showReplyBox)}>
                  {showReplyBox ? null : "Reply"}
                </p>
                {showReplyBox && (
                  <div class="d-flex align-items-center gap-2 flex-row add-comment-section mt-4 mb-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => setShowReplyBox(!showReplyBox)}
                    >
                      <MdCancel />
                    </button>
                    <img
                      class="rounded-circle mr-2"
                      src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
                      alt=""
                      width="50px"
                      height="40"
                    />
                    <input
                      type="text"
                      class="form-control mr-3"
                      placeholder="Write your comment here..."
                      value={replyValue}
                      onChange={(e) => setReplyValue(e.target.value)}
                    />
                    <button
                      className={`${
                        !replyValue
                          ? "submit_comment_disabled"
                          : "submit_comment_btn"
                      }`}
                      type="button"
                      onClick={handleReply}
                      disabled={submitting || !replyValue}
                    >
                      <IoMdSend />
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </li>
      {nestedComments}
    </div>
  );
}

export default function CommentComponents({
  comment,
  postId,
  handleUpdatePost,
  post,
}) {
  const jwtDecode = jwtTokenDecode();
  const [comments, setComments] = useState(comment ?? []);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [replyTo, setReplyTo] = useState(null); // To keep track of which comment we're replying to

  console.log({ jwtDecode });
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    // if (!value) return;
    setSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/create-comment`,
        {
          text: value,
          postId,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
          },
        }
      );

      const newComment = {
        ...response.data.comment,
        user: {
          _id: jwtDecode?._id,
          name: jwtDecode?.name,
          restaurantName: jwtDecode?.restaurantName,
          positionId: "63ea4ee911aef3765b25190d",
          positionName: "Manager",
          email: "demoemployee@gmail.com",
          role: jwtDecode?.role,
          profilePicture: jwtDecode?.profilePicture,
        },
      };
      setComments((prevComments) => {
        const updatedComments = replyTo
          ? prevComments.map((comment) =>
              comment._id === replyTo
                ? {
                    ...comment,
                    children: [...(comment.children || []), newComment],
                  }
                : comment
            )
          : [newComment, ...prevComments];

        // Update the post with the new comments data
        handleUpdatePost({ ...post, comments: updatedComments });

        return updatedComments;
      });
      setValue("");
      setReplyTo(null); // Reset replyTo after submission
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleReply = async (replyTo, value, setReplyValue) => {
    // if (!value) return;

    setSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/social-feed/create-comment`,
        {
          text: value,
          postId,
          parentId: replyTo ?? undefined, // Include parentId if replying to a comment
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`, // Ensure token is properly imported or available
          },
        }
      );

      const newComment = {
        ...response.data.comment,
        user: {
          _id: jwtDecode?._id,
          name: jwtDecode?.name,
          restaurantName: jwtDecode?.restaurantName,
          positionId: "63ea4ee911aef3765b25190d",
          positionName: "Manager",
          email: "demoemployee@gmail.com",
          role: jwtDecode?.role,
          profilePicture: jwtDecode?.profilePicture,
        },
      };
      setComments((prevComments) => {
        const updatedComments = replyTo
          ? prevComments.map((comment) =>
              comment._id === replyTo
                ? {
                    ...comment,
                    children: [...(comment.children || []), newComment],
                  }
                : comment
            )
          : [newComment, ...prevComments];

        // Update the post with the new comments data
        handleUpdatePost({ ...post, comments: updatedComments });

        return updatedComments;
      });
      setReplyValue("");
      setReplyTo(null); // Reset replyTo after submission
    } catch (error) {
      console.error("Error creating comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div class="d-flex gap-2 flex-row add-comment-section mt-4 mb-4">
        <img
          class="rounded-circle mr-2"
          src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
          alt=""
          width="50px"
          height="40"
        />
        <input
          type="text"
          class="form-control mr-3"
          placeholder="Write your comment here..."
          onChange={handleChange}
          value={value}
        />
        <button
          className={`${
            !value ? "submit_comment_disabled" : "submit_comment_btn"
          }`}
          type="button"
          onClick={handleSubmit}
          disabled={submitting || !value}
        >
          <IoMdSend />
        </button>
      </div>

      {/* Display comments */}
      {/* <div className="mt-4">
        {comments.length > 0 && (
          <ul className="list-group">
            {comments
              ?.sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
              ?.map((comment, index) => (
                <Comment
                  key={comment._id}
                  comment={comment}
                  onReply={handleReply}
                  jwtDecode={jwtDecode}
                />
              ))}
          </ul>
        )}
      </div> */}

      {/*   // fixed 0 read only issue */}
      <div className="mt-4">
        {comments.length > 0 && (
          <ul className="list-group">
            {[...comments]
              ?.sort((a, b) => {
                const dateA = a?.createdAt || "";
                const dateB = b?.createdAt || "";
                return dateB.localeCompare(dateA);
              })
              ?.map((comment) => (
                <Comment
                  key={comment._id}
                  comment={comment}
                  onReply={handleReply}
                  jwtDecode={jwtDecode}
                  submitting={submitting}
                />
              ))}
          </ul>
        )}
      </div>
    </>
  );
}
