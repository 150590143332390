export const getCurrency = (country) => {
    switch (country) {
        case "United Kingdom":
            return "£"

        case "United Arab Emirates":
            return "AED"

        case "Italy":
            return "€"

        default:
            return "AED"
    }
}