import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { token } from "../../../utils/authentication";
import BookingDates from "../../../components/frontend/client/BookingDates";
import NotFound from "../../../components/frontend/employee/notFound/notFound";
import { useTranslation } from "react-i18next";

export default function EmployeeHiredHistory() {
  const { t } = useTranslation();
  const [employeeHiredHistory, setEmployeeHiredHistory] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/hired-history`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEmployeeHiredHistory(data?.bookedHistory);
      });
  }, []);

  // console.log(employeeHiredHistory);
  const countDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.toDateString() === end.toDateString()) {
      return "1";
    }

    const timeDifference = Math.abs(end - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1; // Add 1 to include both start and end dates

    return `${dayDifference}`;
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const showModal = (employeeId) => {
    setIsModalVisible(true);
  };

  return (
    <>
      {/*<section className="InnnerDashboardSearch">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="innerDashSearchItems d-flex align-items-center">
                <Link to="/employee-home">
                  <button className="innerdashboardBackButton">
                    <img
                      src="assets/frontend/images/InnerDashboard/arrow.png"
                      className="img-fluid"
                      alt="arrow"
                    />
                  </button>
                </Link>
                <img
                  src={hired}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  className="img-fluid"
                  alt="mapSearch"
                />
                <span className="innerDashSearchItemsSpan">{t("hired_history")}</span>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <div className="row">
        {employeeHiredHistory?.length <= 0 && (
          <NotFound
            title={t("no_hired_history")}
            description={t("you_have_not_been_hired_on_any_resturant_yet.")}
          />
        )}
        {employeeHiredHistory?.map((employeeHiredHistory, index) => (
          <div className="col-md-6 col-lg-6 mb-4">
            <Card
              key={index}
              style={{
                height: "10rem",
                borderRadius: "10px",
                width: "100%",
                backgroundImage:
                  "url(https://source.unsplash.com/featured/?restaurant,dining)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                color: "black",
                fontWeight: "bold",
              }}
              className="mb-4"
            >
              <Card.Body>
                <div className="">
                  {t("you_have_been_booked_by")}&nbsp;
                  <span className="">
                    {employeeHiredHistory.restaurantDetails.restaurantName}
                  </span>
                  &nbsp;{t("which_is_located_at")}&nbsp;
                  <span className="">
                    {employeeHiredHistory.restaurantDetails.restaurantAddress}
                  </span>
                  <div
                    className="details"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className=""
                      style={{
                        background: "#58c8c8",
                        color: "#fff",
                        marginTop: "50px",
                      }}
                      onClick={() => {
                        showModal(employeeHiredHistory.employeeId);
                        setActiveEmployee(employeeHiredHistory);
                      }}
                    >
                      {t("show_booked_dates")}
                    </Button>
                  </div>
                </div>
                <div>
                  <BookingDates
                    isModalVisible={isModalVisible}
                    activeEmployee={activeEmployee}
                    countDates={countDates}
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
}
