import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtTokenDecode } from "../../utils/jwtDecode";
import ReactFlagsSelect from "react-flags-select";
import { io } from "socket.io-client";
import { changeLanguageFunc, getUserId } from "../../utils/commonFunctions";
import { responseNotification } from "../../utils/notifcation";
import notificationSound from "../../layouts/frontend/dashboard/Notification/notification-sound.mp3";
import "./navbar_style.scss";
import LogoIcon from "../../assets/images/logo.png";
import AdminIcon from "../../assets/images/Admin.jpg";
import { IoMdNotificationsOutline } from "react-icons/io";
import Notification from "../frontend/dashboard/Notification/Notification";
import { Dropdown } from "antd";
import { ContextApi } from "../../hooks/ContextApi";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";

function Navbar() {
  const jwt_decode = jwtTokenDecode();
  const [shake, setShake] = useState(false);
  const {
    getShortList,
    notificationCount,
    setNotificationCount,
    loadingShortList,
  } = useContext(ContextApi);
  const { notificationList, unreadCount } = useSelector(
    ({ auth }) => ({
      notificationList: auth?.notifications,
      unreadCount: auth?.unreadCount,
    }),
    shallowEqual
  );

  const navigate = useNavigate();
  const [selected, setSelected] = useState(
    localStorage.getItem("countryCode") || "GB"
  );

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("loginData");

    navigate("/");

    window.location.reload();
  };
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    withCredentials: false,
    transports: ["websocket", "polling", "flashsocket"],
  });
  useEffect(() => {
    socket.on("new_message", (data) => {
      var currentUrl = window.location.href;
      // CHECK IF THE MESSAGE IS FROM Client and the user is in the support page
      if (
        data.message.receiverDetails?.length > 1 &&
        !currentUrl.includes("/admin/support")
      ) {
        const message = `${data?.message?.senderDetails?.name}: ${data.message.text}`;
        responseNotification(message, "success");
        new Audio(notificationSound).play();
        return;
      }
    });
    return () => {
      socket.off("notification");
      socket.off("new_message");
      console.log("WebSocket connection closed.");
    };
  }, []);

  const shakingStyle = {
    animation: shake ? "shake 0.5s cubic-bezier(.36,.07,.19,.97) both" : "",
  };

  const notificationMenu = (
    <Notification
      setNotificationCount={setNotificationCount}
      isSubscriber={false}
    />
  );

  return (
    <nav className="plagit_admin_navbar sb-topnav navbar navbar-expand shadow">
      <Link
        className="navbar-brand ps-3 d-flex navbar_logo"
        to="/admin/dashboard"
      >
        <img className="img-fluid w-25" src={LogoIcon} alt="logo" />
        <h2> PLAGIT ADMIN </h2>
      </Link>
      {/* <!-- Sidebar Toggle--> */}
      {/* <button
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        href="#!"
      >
        <i className="fas fa-bars"></i>
      </button> */}
      {/* <!-- Navbar Search--> */}
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
          {/* <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button> */}
        </div>
      </form>

      {/* <!-- Navbar--> */}
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li className="nav-item lang_nav">
          <ReactFlagsSelect
            defaultCountry="GB"
            // className="menu-flags"
            countries={["GB", "AE", "IT"]}
            selected={selected || "GB"}
            showSelectedLabel={false}
            customLabels={{
              US: "English",
              GB: "English",
              AE: "Arabic",
              IT: "Italian",
            }}
            onSelect={(e) => {
              setSelected(e);
              changeLanguageFunc(e);
            }}
          />
        </li>

        <li className="nav-item">
          <Dropdown overlay={notificationMenu} trigger={["click"]}>
            <a className="Dashboard_navbar_custom_hover nav-link" href="#">
              <div
                className="navLinkImageTextWraper"
                style={{ position: "relative" }}
              >
                <IoMdNotificationsOutline
                  style={{
                    fontSize: "30px",
                    ...shakingStyle,
                  }}
                />
                {unreadCount > 0 && (
                  <span className="bage_count">
                    {unreadCount > 20 ? "20+" : unreadCount}
                  </span>
                )}
              </div>
            </a>
          </Dropdown>
        </li>

        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {/* <i className="fas fa-user fa-fw"></i> */}
            <img className="admin_user_icon" src={AdminIcon} alt="Admin" />
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            {jwt_decode?.superAdmin && (
              <li>
                <Link to="/admin/change-password" className="dropdown-item">
                  Change Password
                </Link>
              </li>
            )}

            {jwt_decode?.superAdmin && (
              <li>
                <hr className="dropdown-divider" />
              </li>
            )}
            <li>
              <button onClick={handleLogout} className="dropdown-item">
                Logout
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
