import * as requestFromServer from "./clientRequest";
import { callTypes, clientSlice } from "./clientSlice";
import { getUserCurrencyByCountry } from "../../../utils/commonFunctions";
import dayjs from "dayjs";
import { minutestoHours } from "../../../utils/date";
const { actions } = clientSlice;

export const fetchShortlist = (limit, page) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchShortlistRequest(limit, page)
    .then((response) => {
      const { data } = response;
      if (data.status === "success") {
        dispatch(
          actions.shortlistFetched({
            entities: data.shortList,
            totalCount: data.count,
            totalPages: Math.ceil(data.count / limit),
            shortListIds: data.shortListIds,
          })
        );
      } else {
        dispatch(
          actions.catchError({
            error: "Failed to fetch shortlist",
            callType: callTypes.action,
          })
        );
      }
    })
    .catch((error) => {
      console.log("error~~", error);
      dispatch(
        actions.catchError({ error: error.message, callType: callTypes.action })
      );
    });
};

// Fetch my employees list
export const fetchMyEmployeesList =
  (startDate, endDate, hiredBy) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));

    return requestFromServer
      .MyEmployeeList(startDate, endDate, hiredBy) // Adjust according to your API call setup
      .then((response) => {
        if (response.status === 200) {
          const employees = response.data.details.result[0]?.employee || [];
          const totalCount = response.data.details?.totalCount || 1; // Adjust if you have a total count returned
          const totalPages = Math.ceil(totalCount / 20); // Assuming 20 as limit for pages, change accordingly

          dispatch(
            actions.myEmployeeListFetched({
              entities: employees,
              totalCount: totalCount,
              totalPages: totalPages,
            })
          );
        } else {
          dispatch(
            actions.catchError({
              error: "Failed to fetch employees",
              callType: callTypes.list,
            })
          );
        }
      })
      .catch((error) => {
        console.log("error~~", error);
        dispatch(
          actions.catchError({
            error: error.message || "Failed to fetch employees",
            callType: callTypes.list,
          })
        );
      });
  };
// fetch dashboard history  for client
export const fetchHistoryList =
  (clientId, currentPage, pageSize, dateFilter, getName, status) =>
  (dispatch) => {
    // Dispatch action to indicate the call has started
    dispatch(actions.startCall({ callType: "list" }));

    return requestFromServer
      .fetchCheckInCheckOutHistories(
        clientId,
        currentPage,
        pageSize,
        dateFilter,
        getName,
        status
      )
      .then((response) => {
        if (response && response?.data.statusCode === 200) {
          const checkInCheckOutHistories = response.data.result.map((item) => {
            const finalCheckIn =
              item?.checkInCheckOutDetails?.clientCheckInTime ||
              item?.checkInCheckOutDetails?.checkInTime;
            const finalCheckOut =
              item?.checkInCheckOutDetails?.clientCheckOutTime ||
              item?.checkInCheckOutDetails?.checkOutTime;
            const finalBreakTime =
              item?.checkInCheckOutDetails?.clientBreakTime ||
              item?.checkInCheckOutDetails?.breakTime;

            const isEditCheckIn = item?.checkInCheckOutDetails
              ?.clientCheckInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) !==
                dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : false;
            const isEditCheckOut = item?.checkInCheckOutDetails
              ?.clientCheckOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) !==
                dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : false;
            const isEditBreakTime = item?.checkInCheckOutDetails
              ?.clientBreakTime
              ? item?.checkInCheckOutDetails?.breakTime !==
                item?.checkInCheckOutDetails?.clientBreakTime
              : false;

            return {
              date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
              id: item?._id,
              country: item?.restaurantDetails?.countryName,
              restaurantName: item?.restaurantDetails?.restaurantName,
              employeeName: item?.employeeDetails?.name,
              position: item?.employeeDetails?.positionName,
              restaurantRate: `${
                item?.employeeDetails?.hourlyRate
              } ${getUserCurrencyByCountry(
                item?.restaurantDetails?.countryName
              )}/Hour`,
              customerRate: `${
                item?.employeeDetails?.contractorHourlyRate
              } ${getUserCurrencyByCountry(
                item?.restaurantDetails?.countryName
              )}/Hour`,
              checkIn: item?.checkInCheckOutDetails?.checkInTime
                ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckIn: finalCheckIn
                ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
                : null,
              isEditCheckIn,

              checkOut: item?.checkInCheckOutDetails?.checkOutTime
                ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckOut: finalCheckOut
                ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
                : null,
              isEditCheckOut,

              break: item?.checkInCheckOutDetails?.breakTime,
              finalBreakTime: finalBreakTime ? finalBreakTime : 0,
              isEditBreakTime,

              totalHours: finalCheckOut
                ? minutestoHours(
                    dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60
                  )
                : null,
              workedHour: item?.workedHour,
              paid: item?.paid,
              totalAmount: finalCheckOut
                ? `${(
                    (dayjs(finalCheckOut)
                      .diff(dayjs(finalCheckIn), "seconds", true)
                      .toFixed(0) -
                      finalBreakTime * 60) *
                    (item?.employeeDetails?.hourlyRate / 3600)
                  ).toFixed(2)} ${getUserCurrencyByCountry(
                    item?.restaurantDetails?.countryName
                  )}`
                : null,
              clientComment: item?.checkInCheckOutDetails?.clientComment,
              mainItem: item,
            };
          });

          // Dispatch action to update state with the fetched data
          let totalPages = 1;
          const total = response.data.total;
          if (response.data.result.length) {
            totalPages = Math.ceil(total / pageSize);
          }
          dispatch(
            actions.historyListFetched({
              entities: checkInCheckOutHistories,
              totalCount: total,
              totalPages: totalPages,
            })
          );
        } else {
          // Dispatch error handling in case of a bad status code
          dispatch(
            actions.catchError({ error: response.errors, callType: "list" })
          );
        }
      })
      .catch((error) => {
        // Dispatch error handling in case of a catch block
        console.error("Error fetching check-in-check-out histories", error);
        dispatch(actions.catchError({ error, callType: "list" }));
      });
  };

// employee payment history data for employee
export const fetchEmployeePaymentHistoryList =
  (employeeId, currentPage, pageSize, dateFilter, getName, status) =>
  async (dispatch) => {
    dispatch(actions.startCall({ callType: "list" }));

    try {
      const response = await requestFromServer.fetchEmployeePaymentHistoryList(
        employeeId,
        currentPage,
        pageSize,
        status,
        dateFilter,
        getName
      );

      if (response?.data.statusCode === 200) {
        const checkInCheckOutHistories = response.data.result.map((item) => {
          const finalCheckIn =
            item?.checkInCheckOutDetails?.clientCheckInTime ||
            item?.checkInCheckOutDetails?.checkInTime;
          const finalCheckOut =
            item?.checkInCheckOutDetails?.clientCheckOutTime ||
            item?.checkInCheckOutDetails?.checkOutTime;
          const finalBreakTime =
            item?.checkInCheckOutDetails?.clientBreakTime ||
            item?.checkInCheckOutDetails?.breakTime;

          const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
            ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) !==
              dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : false;

          const isEditCheckOut = item?.checkInCheckOutDetails
            ?.clientCheckOutTime
            ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) !==
              dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : false;

          const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
            ? item?.checkInCheckOutDetails?.breakTime !==
              item?.checkInCheckOutDetails?.clientBreakTime
            : false;

          return {
            date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
            id: item?._id,
            country: item?.restaurantDetails?.countryName,
            restaurantName: item?.restaurantDetails?.restaurantName,
            employeeName: item?.employeeDetails?.name,
            position: item?.employeeDetails?.positionName,
            contractorHourlyRate: item?.employeeDetails?.contractorHourlyRate,
            restaurantRate: `${
              item?.employeeDetails?.hourlyRate
            } ${getUserCurrencyByCountry(
              item?.restaurantDetails?.countryName
            )}/Hour`,
            customerRate: `${
              item?.employeeDetails?.contractorHourlyRate
            } ${getUserCurrencyByCountry(
              item?.restaurantDetails?.countryName
            )}/Hour`,
            checkIn: item?.checkInCheckOutDetails?.checkInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckIn: finalCheckIn
              ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
              : null,
            isEditCheckIn,

            checkOut: item?.checkInCheckOutDetails?.checkOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckOut: finalCheckOut
              ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
              : null,
            isEditCheckOut,

            break: item?.checkInCheckOutDetails?.breakTime,
            finalBreakTime: finalBreakTime || 0,
            isEditBreakTime,

            totalHours: finalCheckOut
              ? minutestoHours(
                  dayjs(finalCheckOut).diff(
                    dayjs(finalCheckIn),
                    "seconds",
                    true
                  ) -
                    finalBreakTime * 60
                )
              : null,

            totalAmount: finalCheckOut
              ? `${(
                  (dayjs(finalCheckOut).diff(
                    dayjs(finalCheckIn),
                    "seconds",
                    true
                  ) -
                    finalBreakTime * 60) *
                  (item?.employeeDetails?.hourlyRate / 3600)
                ).toFixed(2)} ${getUserCurrencyByCountry(
                  item?.restaurantDetails?.countryName
                )}`
              : null,

            clientComment: item?.checkInCheckOutDetails?.clientComment,
            vat: item?.vat,
            vatAmount: item?.vatAmount,
            totalAmountWithVat: item?.totalAmount,
            employeeAmount: item?.employeeAmount,
            status: item?.status,
            workedHour: item?.workedHour,
            mainItem: item,
          };
        });
        let totalPages = 1;
        if (response.data.total && pageSize) {
          totalPages = Math.ceil(response.data.total / pageSize);
        }
        // Dispatch the success action with the mapped data
        dispatch(
          actions.employeePaymentHistoryListFetched({
            entities: checkInCheckOutHistories,
            totalCount: response.data.total,
            totalPages,
          })
        );
      } else {
        // Dispatch an error action if there's a problem with the response
        dispatch(
          actions.catchError({ error: response.errors, callType: "list" })
        );
      }
    } catch (error) {
      // Dispatch an error action in case of a failure
      console.error("Error fetching employees:", error);
      dispatch(actions.catchError({ error, callType: "list" }));
    }
  };
// client payment history data for client
export const fetchClientPaymentHistoryList = (
  clientId,
  currentPage,
  pageSize,
  dateFilter,
  getName,
  status
) => {
  return async (dispatch) => {
    dispatch(actions.startCall({ callType: "list" }));

    try {
      const response = await requestFromServer.fetchCLientPaymentHistoryList(
        clientId,
        currentPage,
        pageSize,
        status,
        dateFilter,
        getName
      );

      if (response?.data.statusCode === 200) {
        const checkInCheckOutHistories = response.data.result.map((item) => {
          const finalCheckIn =
            item?.checkInCheckOutDetails?.clientCheckInTime ||
            item?.checkInCheckOutDetails?.checkInTime;
          const finalCheckOut =
            item?.checkInCheckOutDetails?.clientCheckOutTime ||
            item?.checkInCheckOutDetails?.checkOutTime;
          const finalBreakTime =
            item?.checkInCheckOutDetails?.clientBreakTime ||
            item?.checkInCheckOutDetails?.breakTime;

          const isEditCheckIn = item?.checkInCheckOutDetails?.clientCheckInTime
            ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) !==
              dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : false;

          const isEditCheckOut = item?.checkInCheckOutDetails
            ?.clientCheckOutTime
            ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) !==
              dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : false;

          const isEditBreakTime = item?.checkInCheckOutDetails?.clientBreakTime
            ? item?.checkInCheckOutDetails?.breakTime !==
              item?.checkInCheckOutDetails?.clientBreakTime
            : false;

          return {
            date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
            id: item?._id,
            country: item?.restaurantDetails?.countryName,
            restaurantName: item?.restaurantDetails?.restaurantName,
            employeeName: item?.employeeDetails?.name,
            position: item?.employeeDetails?.positionName,
            contractorHourlyRate: item?.employeeDetails?.contractorHourlyRate,
            restaurantRate: `${
              item?.employeeDetails?.hourlyRate
            } ${getUserCurrencyByCountry(
              item?.restaurantDetails?.countryName
            )}/Hour`,
            customerRate: `${
              item?.employeeDetails?.contractorHourlyRate
            } ${getUserCurrencyByCountry(
              item?.restaurantDetails?.countryName
            )}/Hour`,
            checkIn: item?.checkInCheckOutDetails?.checkInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckIn: finalCheckIn
              ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
              : null,
            isEditCheckIn,
            checkOut: item?.checkInCheckOutDetails?.checkOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                  .format("hh:mm:ss A")
                  .toString()
              : null,
            finalCheckOut: finalCheckOut
              ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
              : null,
            isEditCheckOut,
            break: item?.checkInCheckOutDetails?.breakTime,
            finalBreakTime: finalBreakTime || 0,
            isEditBreakTime,
            totalHours: finalCheckOut
              ? minutestoHours(
                  dayjs(finalCheckOut).diff(
                    dayjs(finalCheckIn),
                    "seconds",
                    true
                  ) -
                    finalBreakTime * 60
                )
              : null,
            totalAmount: finalCheckOut
              ? `${(
                  (dayjs(finalCheckOut).diff(
                    dayjs(finalCheckIn),
                    "seconds",
                    true
                  ) -
                    finalBreakTime * 60) *
                  (item?.employeeDetails?.hourlyRate / 3600)
                ).toFixed(2)} ${getUserCurrencyByCountry(
                  item?.restaurantDetails?.countryName
                )}`
              : null,
            clientComment: item?.checkInCheckOutDetails?.clientComment,
            vat: item?.vat,
            vatAmount: item?.vatAmount,
            totalAmountWithVat: item?.totalAmount,
            employeeAmount: item?.employeeAmount,
            status: item?.status,
            workedHour: item?.workedHour,
            mainItem: item,
          };
        });

        // Calculate total pages
        let totalPages = 1;
        if (response.data.total && pageSize) {
          totalPages = Math.ceil(response.data.total / pageSize);
        }

        // Dispatch success with data
        dispatch(
          actions.clientPaymentHistoryListFetched({
            entities: checkInCheckOutHistories,
            totalCount: response.data.total,
            totalPages,
          })
        );
      } else {
        // Dispatch an error action if there's a problem with the response
        dispatch(
          actions.catchError({ error: response.errors, callType: "list" })
        );
      }
    } catch (error) {
      // Handle API error
      dispatch(actions.catchError({ error, callType: "list" }));
    }
  };
};

// employee checkin checkout dashboard list
export const fetchEmployeeCheckInCheckoutHistoryList =
  (currentPage, pageSize, dateFilter) => async (dispatch) => {
    dispatch(actions.startCall({ callType: "list" }));

    try {
      const response =
        await requestFromServer.fetchEmployeeCheckInCheckoutHistoryList(
          currentPage,
          pageSize,
          dateFilter
        );

      if (response?.data.statusCode === 200) {
        const checkInCheckOutHistories =
          response.data.checkInCheckOutHistory.map((item) => {
            const finalCheckIn =
              item?.checkInCheckOutDetails?.clientCheckInTime ||
              item?.checkInCheckOutDetails?.checkInTime;
            const finalCheckOut =
              item?.checkInCheckOutDetails?.clientCheckOutTime ||
              item?.checkInCheckOutDetails?.checkOutTime;
            const finalBreakTime =
              item?.checkInCheckOutDetails?.clientBreakTime ||
              item?.checkInCheckOutDetails?.breakTime;

            const isEditCheckIn = item?.checkInCheckOutDetails
              ?.clientCheckInTime
              ? dayjs(item?.checkInCheckOutDetails?.checkInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) !==
                dayjs(item?.checkInCheckOutDetails?.clientCheckInTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : false;

            const isEditCheckOut = item?.checkInCheckOutDetails
              ?.clientCheckOutTime
              ? dayjs(item?.checkInCheckOutDetails?.checkOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) !==
                dayjs(item?.checkInCheckOutDetails?.clientCheckOutTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : false;

            const isEditBreakTime = item?.checkInCheckOutDetails
              ?.clientBreakTime
              ? item?.checkInCheckOutDetails?.breakTime !==
                item?.checkInCheckOutDetails?.clientBreakTime
              : false;

            return {
              date: item?.checkInCheckOutDetails?.checkInTime.split(" ")[0],
              id: item?._id,
              country: item?.restaurantDetails?.countryName,
              restaurantName: item?.restaurantDetails?.restaurantName,
              employeeName: item?.employeeDetails?.name,
              position: item?.employeeDetails?.positionName,
              restaurantRate: `${
                item?.employeeDetails?.hourlyRate
              } ${getUserCurrencyByCountry(
                item?.restaurantDetails?.countryName
              )}/Hour`,
              customerRate: `${
                item?.employeeDetails?.contractorHourlyRate
              } ${getUserCurrencyByCountry(
                item?.restaurantDetails?.countryName
              )}/Hour`,
              checkIn: item?.checkInCheckOutDetails?.checkInTime
                ? dayjs(item?.checkInCheckOutDetails?.checkInTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckIn: finalCheckIn
                ? dayjs(finalCheckIn).format("hh:mm:ss A").toString()
                : null,
              isEditCheckIn,

              checkOut: item?.checkInCheckOutDetails?.checkOutTime
                ? dayjs(item?.checkInCheckOutDetails?.checkOutTime)
                    .format("hh:mm:ss A")
                    .toString()
                : null,
              finalCheckOut: finalCheckOut
                ? dayjs(finalCheckOut).format("hh:mm:ss A").toString()
                : null,
              isEditCheckOut,

              break: item?.checkInCheckOutDetails?.breakTime,
              finalBreakTime: finalBreakTime || 0,
              isEditBreakTime,

              totalHours: finalCheckOut
                ? minutestoHours(
                    dayjs(finalCheckOut).diff(
                      dayjs(finalCheckIn),
                      "seconds",
                      true
                    ) -
                      finalBreakTime * 60
                  )
                : null,

              totalAmount: finalCheckOut
                ? `${(
                    (dayjs(finalCheckOut).diff(
                      dayjs(finalCheckIn),
                      "seconds",
                      true
                    ) -
                      finalBreakTime * 60) *
                    (item?.employeeDetails?.hourlyRate / 3600)
                  ).toFixed(2)} ${getUserCurrencyByCountry(
                    item?.restaurantDetails?.countryName
                  )}`
                : null,

              clientComment: item?.checkInCheckOutDetails?.clientComment,
              mainItem: item,
            };
          });

        let totalPages = 1;
        if (response.data.total && pageSize) {
          totalPages = Math.ceil(response.data.total / pageSize);
        }

        // Dispatch the success action with the mapped data
        dispatch(
          actions.employeeCheckinCheckoutListFetched({
            entities: checkInCheckOutHistories,
            totalCount: response.data.total,
            totalPages,
          })
        );
      } else {
        // Handle error if status code is not 200
        dispatch(
          actions.catchError({ error: response.errors, callType: "list" })
        );
      }
    } catch (error) {
      // Handle any errors
      console.error("Error fetching check-in/check-out histories:", error);
      dispatch(actions.catchError({ error, callType: "list" }));
    }
  };

// fetchPosts
export const fetchPosts = (type, limit, page, userId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .postList(type, limit, page, userId)
    .then((response) => {
      // console.log("response-fetch-posts~~", response);
      if (response.data.status === "success") {
        const { posts, total, limit = 20 } = response.data.socialFeeds;
        dispatch(
          actions.postsFetched({
            entities: posts,
            totalCount: total,
            totalPages: Math.ceil(total / limit),
            type: type,
          })
        );
      }
    })
    .catch((error) => {
      console.log("error~~", error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

// create post
export const createPost = (content, media, user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createPost(content, media)
    .then((response) => {
      // console.log("create-response~~", response);
      if (response.data.status === "success") {
        const newPost = response.data.socialFeed;
        const createPostData = {
          ...newPost,
          user,
        };
        console.log("create post data", createPostData);
        dispatch(
          actions.postCreated({
            post: createPostData,
          })
        );
      }
    })
    .catch((error) => {
      console.log("error-create-post~~", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// update post
export const updatePost = (postId, content, media) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updatePost(postId, content, media)
    .then((response) => {
      if (response.data.status === "success") {
        const updatedPost = response.data.socialFeed;
        dispatch(actions.postUpdated({ post: updatedPost }));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// delete post
export const deletePost = (postId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deletePost(postId)
    .then((response) => {
      console.log("delete response~~", response);
      if (response?.data?.status === "success") {
        dispatch(actions.postDeleted({ postId }));
        // dispatch(fetchPosts("active", 20, 1));
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getJobList = (queryString, limit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .jobList(queryString, limit)
    .then((response) => {
      if (response.data.status == "success") {
        const { jobs } = response.data;
        dispatch(
          actions.jobListFetched({
            entities: jobs,
            totalCount: response.data?.total,
            totalPages: Math.ceil(response.data.total / limit),
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getMyJobList = (queryString, limit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .jobList(queryString, limit)
    .then((response) => {
      if (response.data.status == "success") {
        const { jobs } = response.data;
        dispatch(
          actions.myJobListFetched({
            entities: jobs,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
