import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApi } from "../../../../hooks/ContextApi";
import { token } from "../../../../utils/authentication";
import { formatNotificationDate } from "../../../../utils/notifcation";
import "./notification_style.scss";
import { Link } from "react-router-dom";
import API from "../../../../utils/API";
import { MdDelete } from "react-icons/md";
import { FaBell } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import * as Action from "../../../../pages/Auth/_redux/authActions";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { useCommon } from "../../../../contexts/common-context";

export default function Notification({ isSubscriber }) {
  const dispatch = useDispatch();
  const { setIsVisibleNotifition } = useCommon();
  // const [notificationList, setNotificationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { notificationList, unreadCount } = useSelector(
    ({ auth }) => ({
      notificationList: auth?.notifications ?? [],
      unreadCount: auth?.unreadCount ?? 0,
    }),
    shallowEqual
  );
  const scrollDiv = useRef(null);
  const {
    shouldRefetchNotificationList,
    setShouldRefetchNotificationList,
    notificationCount,
    setNotificationCount,
    getShortList,
    setShortList,
  } = useContext(ContextApi);
  // const fetchNotifications = (page = 1) => {
  //   setIsLoading(true); // Set loading to true
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/notifications/list?page=${page}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token()}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (page === 1) {
  //         setNotificationList(res.data.notifications);
  //       } else {
  //         setNotificationList((prevNotifications) => [
  //           ...prevNotifications,
  //           ...res.data.notifications,
  //         ]);
  //       }
  //     })
  //     .finally(() => {
  //       setIsLoading(false); // Set loading to false
  //     });
  // };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollDiv.current;

    if (scrollTop + clientHeight >= scrollHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    dispatch(Action.getNotificationList(currentPage));
    dispatch(Action.getNotificationUnreadCount());
  }, [currentPage, notificationCount, dispatch]);

  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollDiv.current) {
        scrollDiv.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const handleNotificationClick = (id) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/notifications/update-status`,
        {
          id: id,
          readStatus: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then(() => {
        dispatch(Action.getNotificationList(1));
        dispatch(Action.getNotificationUnreadCount());
      });
  };

  const handleReadAll = async () => {
    setIsLoading(true);
    await API.put("/notifications/update-status-all", {
      readStatus: true,
    }).then(async (res) => {
      if (res.data.status == "success") {
        await dispatch(Action.getNotificationList(1));
        await dispatch(Action.getNotificationUnreadCount());
      }
    });
    setIsLoading(false);
  };

  const handleDeleteOne = async (id) => {
    setIsLoading(true);
    await API.delete(`/notifications/${id}`).then(async (res) => {
      if (res.data.status == "success") {
        await dispatch(Action.getNotificationList(1));
        await dispatch(Action.getNotificationUnreadCount());
      }
    });
    setIsLoading(false);
  };

  const handleGetRoute = (link) => {
    setIsVisibleNotifition(false);
    if (link == "/client-dashboard?type=candidate" && isSubscriber) {
      localStorage.setItem("client_tab", "employees");
      return link;
    }

    if (link == "/candidate/home?type=history") {
      return link;
    }

    return link;
  };

  // console.log("notifications~~", notificationList);
  return (
    <div className="notifications_card">
      <div className="notifications_card_header d-flex align-items-center justify-content-between">
        <div>Notifications</div>
        <small
          style={{
            fontSize: "11px",
            color: "rgb(88, 200, 200)",
            cursor: "pointer",
          }}
          onClick={() => {
            handleReadAll();
          }}
        >
          Read All
        </small>
      </div>
      <div className="notifications_card_body" ref={scrollDiv}>
        {notificationList &&
          notificationList?.length > 0 &&
          notificationList?.map((item) => (
            <div
              className="d-flex justify-content-between gap-3 align-items-start notification_items"
              key={item.id}
              onClick={() => handleNotificationClick(item._id)}
            >
              <div className="border rounded-circle p-1">
                <FaBell className="fs-5" />
              </div>
              <div className="">
                {item?.isLink ? (
                  <Link
                    to={handleGetRoute(item?.webRoute)}
                    // to="/view-media-post"
                    className="notification_title"
                    style={{
                      fontWeight: item.readStatus === true ? "normal" : "bold",
                    }}
                  >
                    {item.text}
                  </Link>
                ) : (
                  <p className="notification_title">{item.text}</p>
                )}

                <small className="notification_time">
                  {formatNotificationDate(item.createdAt)}
                </small>
              </div>
              <button
                className="btn btn-sm btn-outline-danger"
                onClick={() => {
                  handleDeleteOne(item?._id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          ))}
        {isLoading && <p>Loading...</p>} {/* Display loading text */}
      </div>
    </div>
  );
}
