import React, { useEffect, useState } from "react";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import Loader from "../../../components/loadar/Loader";
import NotFound from "../../../components/frontend/employee/notFound/notFound";
import { useDispatch } from "react-redux";
import * as Action from "../_redux/candidateActions";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import JobPostCard from "../../../components/Cards/JobPost";
import JobPostSkeleton from "../../../components/Cards/JobPost/JobPostSkeleton";

export default function JobPostSection() {
    const dispatch = useDispatch();
    const jwtDecode = jwtTokenDecode();
    //   const [loading, setLoading] = useState(false);
    const [myJobsList, setMyJobsList] = useState();
    //   const [jobsList, setJobsList] = useState();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);
    //   const [totalPages, setTotalPages] = useState(1);
    const { jobsList, totalPages, loading } = useSelector(
        ({ candidate }) => ({
            loading: candidate.listLoading,
            jobsList: candidate.job.entities,
            totalPages: candidate.job.totalPages,
        }),
        shallowEqual
    );

    //   const fetchJob = async () => {
    //     try {
    //       setLoading(true);
    //       const res = await axios.get(
    //         jwtDecode?.role == "CLIENT"
    //           ? `${process.env.REACT_APP_API_BASE_URL}/job?clientId=${jwtDecode?._id}&limit=${limit}&page=${page}`
    //           : `${process.env.REACT_APP_API_BASE_URL}/job?status=PUBLISHED&limit=${limit}&page=${page}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token()}`,
    //           },
    //         }
    //       );

    //       if (res.data?.status == "success") {
    //         // console.log("Job Lis: ", res?.data?.jobs);
    //         setJobsList(res?.data?.jobs);
    //         setTotalPages(Math.ceil(res.data.total / limit));
    //         setLoading(false);
    //       } else {
    //         // setIsSubscriber(false);
    //         console.log("false");
    //         setLoading(false);
    //       }
    //     } catch (error) {
    //       // setIsSubscriber(false);
    //       console.log("error");
    //       setLoading(true);
    //     }

    //     setLoading(false);
    //   };

    useEffect(() => {
        dispatch(Action.getJobList(limit, page));
    }, [dispatch, page, limit]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    // console.log({ jwtDecode });

    return (
        <div style={styles.container}>
            {jwtDecode?.role === "CLIENT" && myJobsList?.length > 0 && (
                <Section
                    title={`My Job Posts (${myJobsList?.length})`}
                    jobs={myJobsList}
                    jwtDecode={jwtDecode}
                    loading={false}
                />
            )}
            <Section
                title="All Job Posts"
                jobs={jobsList}
                jwtDecode={jwtDecode}
                loading={loading}
            />
            {jobsList?.length > 0 && (
                <section className="pagination">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination paginationLiA">
                                        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                                            <a
                                                className="page-link"
                                                href="#"
                                                aria-label="Previous"
                                                onClick={() => handlePageChange(page - 1)}
                                            >
                                                <span aria-hidden="true">«</span>
                                            </a>
                                        </li>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li
                                                className={`page-item ${page === index + 1 ? "active" : ""
                                                    }`}
                                                key={index}
                                            >
                                                <a
                                                    className="page-link"
                                                    href="#"
                                                    onClick={() => handlePageChange(index + 1)}
                                                    style={
                                                        page === index + 1
                                                            ? { backgroundColor: "#58c8c8" }
                                                            : {}
                                                    }
                                                >
                                                    {index + 1}
                                                </a>
                                            </li>
                                        ))}

                                        <li
                                            className={`page-item ${page === totalPages ? "disabled" : ""
                                                }`}
                                        >
                                            <a
                                                className="page-link"
                                                href="#"
                                                aria-label="Next"
                                                onClick={() => handlePageChange(page + 1)}
                                            >
                                                <span aria-hidden="true">»</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

function Section({ title, jobs, jwtDecode, loading }) {
    return (
        <div style={styles.section}>
            <h2 className="mt-3" style={styles.sectionTitle}>
                {title}
            </h2>
            {loading ? (

                <div className="row">
                    <div className="col-md-4">
                        <JobPostSkeleton />
                    </div>
                    <div className="col-md-4">
                        <JobPostSkeleton />
                    </div>
                    <div className="col-md-4">
                        <JobPostSkeleton />
                    </div>
                </div>
                // <div style={{ paddingBottom: "100px" }}>
                //     <Loader />
                // </div>
            ) : jobs?.length > 0 ? (
                <div style={styles.jobGrid}>
                    {jobs?.map((job, index) => (
                        <JobPostCard key={index} job={job} jwtDecode={jwtDecode} />
                    ))}
                </div>
            ) : (
                <NotFound
                    title={"No Job Available"}
                // description={t("you_have_not_been_booked.")}
                />
            )}
        </div>
    );
}

const styles = {
    container: {
        padding: "20px",
        borderRadius: "15px",
        border: "1px solid #E0E0E0",
        // maxWidth: "1200px", // Adjusted for wider containers
        margin: "auto",
        backgroundColor: "white",
        marginTop: "20px",
    },
    section: {
        marginBottom: "30px",
    },
    sectionTitle: {
        fontSize: "20px",
        fontWeight: "700",
        marginBottom: "15px",
        color: "#333",
    },
    jobGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "20px",
    },
    card: {
        borderRadius: "15px",
        border: "1px solid #E0E0E0", // Added border to match the original design
        padding: "20px",
        backgroundColor: "#fff",
        position: "relative", // For positioning button inside card
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    icon: {
        width: "40px", // Adjusted size for better visual
        height: "40px",
        marginRight: "10px",
    },
    cardTitle: {
        fontSize: "18px",
        fontWeight: "600",
        color: "#333",
    },
    cardBody: {
        fontSize: "14px",
        color: "#555",
        marginBottom: "40px",
    },
    cardText: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        color: "#333", // Adjusted text color
    },
    emoji: {
        marginRight: "8px",
        marginLeft: "7px",
    },
    button: {
        background: "linear-gradient(to right, #58C8C8, #3F8E9B)",
        color: "#fff",
        border: "none",
        borderRadius: "15px 0px 15px 0px", // Adjusted border radius for custom corners
        padding: "5px 15px", // Adjusted padding for better spacing
        cursor: "pointer",
        textAlign: "center",
        position: "absolute",
        bottom: "0px",
        right: "0px",
    },
};
