import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtTokenDecode } from "../../../utils/jwtDecode";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BiCamera } from "react-icons/bi";
import { token } from "../../../utils/authentication";
import default_profile from "../../../assets/images/Client.jpg";
import * as Actions from "../../../pages/Client/_redux/clientActions";
import { clientSlice } from "../../../pages/Client/_redux/clientSlice";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, Spin, Upload } from "antd";

const { actions } = clientSlice;

export default function CreatePost({
  setCurrentPage,
  edit,
  selectedPost,
  setSelectedPost,
  handleUpdatePost,
  post,
  fetchPostListData,
}) {
  const jwtDecode = jwtTokenDecode();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [postCreateLoading, setPostCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  console.log("files~~", files);
  const { actionLoading, isCreatePostSuccess } = useSelector(({ client }) => ({
    actionLoading: client.actionsLoading,
    isCreatePostSuccess: client.isCreatePostSuccess,
  }));

  useEffect(() => {
    if (isCreatePostSuccess) {
      toast.success("Post Uploaded");
      setSelectedPost();
      setContent("");
      setFiles([]);
      setSelectedFiles([]);
      dispatch(actions.postCreatedSuccess(false));
    }
  }, [dispatch, isCreatePostSuccess, setSelectedPost]);

  console.log("isCreatePostSuccess~~", isCreatePostSuccess);

  function getFileType(file) {
    if (!file) {
      return "unknown";
    }

    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      return "image";
    } else if (fileType.startsWith("video/")) {
      return "video";
    }

    return "unknown";
  }

  // const handleUpload = async (e) => {
  //     setLoading(true);
  //     const file = e.target.files[0];

  //     if (!file) {
  //         alert("No file selected.");
  //         return;
  //     }

  //     setSelectedFiles((prev) => [...prev, file]);

  //     const formData = new FormData();
  //     formData.append("file", file);

  //     await axios
  //         .post(
  //             `${process.env.REACT_APP_API_BASE_URL}/commons/upload-file`,
  //             formData,
  //             {
  //                 headers: {
  //                     "Content-Type": "multipart/form-data",
  //                     Authorization: `Bearer ${token()}`,
  //                 },
  //             }
  //         )
  //         .then((res) => {
  //             if (res.data?.status == "success") {
  //                 setFiles((prev) => [
  //                     ...prev,
  //                     { url: res.data?.data?.file, type: getFileType(file) },
  //                 ]);
  //             } else {
  //                 toast.error("Invalid File Type");
  //             }
  //         })
  //         .catch((err) => toast.error("Invalid File Type"));

  //     setLoading(false);
  // };

  const handleOnSubmitPost = async () => {
    console.log("handleOnSubmitPost");
    setPostCreateLoading(true);
    if (selectedPost && Object.keys(selectedPost).length > 0) {
      // update post
      dispatch(Actions.updatePost(selectedPost?._id, content, files));

      // await axios
      //   .put(
      //     `${process.env.REACT_APP_API_BASE_URL}/social-feed/update/${selectedPost?._id}`,
      //     {
      //       content: content,
      //       media: files,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token()}`,
      //       },
      //     }
      //   )
      //   .then(async (res) => {
      //     if (res.data?.status == "success") {
      //       // await fetchPostListData();
      //       await toast.success("Post Updated");
      //       // const updatedPost = {
      //       //   ...post,
      //       //   content: res.data?.socialFeed?.content,
      //       //   media: res.data?.socialFeed?.media,
      //       // };

      //       // handleUpdatePost(updatedPost);
      //       await setSelectedPost();
      //       await setContent("");
      //       await setFiles([]);
      //       await setSelectedFiles([]);
      //       // await setCurrentPage(1);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("upload~~", err);
      //     toast.error("Invalid File Type");
      //   });
      setFileList([]);
    } else {
      // create a new post
      dispatch(Actions.createPost(content, files, user));

      // await axios
      //   .post(
      //     `${process.env.REACT_APP_API_BASE_URL}/social-feed/create`,
      //     {
      //       content: content,
      //       media: files,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token()}`,
      //       },
      //     }
      //   )
      //   .then(async (res) => {
      //     console.log("response photo~~", res);
      //     if (res.data?.status == "success") {
      //       await setCurrentPage(1);
      //       // await fetchPostListData();
      //       await toast.success("Post Uploaded");
      //       await setSelectedPost();
      //       await setContent("");
      //       await setFiles([]);
      //       await setSelectedFiles([]);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("upload~~", err);
      //     toast.error("Invalid File Type");
      //   });
      setFileList([]);
    }
    setPostCreateLoading(false);
  };

  useEffect(() => {
    if (selectedPost && Object.keys(selectedPost).length > 0) {
      setContent(selectedPost?.content);
      setFiles(selectedPost?.media);
      setSelectedFiles(selectedPost?.media);
    } else {
      setContent("");
      setFiles([]);
    }
  }, [selectedPost]);

  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleUpload = async (file) => {
    setUploadLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/commons/media-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token()}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.status === "success") {
          setFiles((prev) => [
            ...prev,
            { url: res.data?.data?.file, type: getFileType(file) },
          ]);
        } else {
          alert("Invalid File Type");
        }
      })
      .catch((err) => alert("Invalid File Type"));

    setUploadLoading(false);
  };

  const beforeUpload = (file) => {
    handleUpload(file);
    return false; // Prevent default Ant Design upload behavior
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="create-post-card">
      <div className="create-post-header">
        {jwtDecode?.profilePicture ? (
          <img
            src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${jwtDecode?.profilePicture}`}
            alt={
              jwtDecode?.role === "CLIENT"
                ? jwtDecode?.restaurantName
                : jwtDecode?.name
            }
            className="user-image"
          />
        ) : (
          <img
            src={default_profile}
            alt={
              jwtDecode?.role === "CLIENT"
                ? jwtDecode?.restaurantName
                : jwtDecode?.name
            }
            className="user-image"
          />
        )}
        <span>
          {jwtDecode?.role === "CLIENT"
            ? jwtDecode?.restaurantName
            : jwtDecode?.name}
        </span>
      </div>

      <div className="post_body">
        <textarea
          className="textarea"
          placeholder="Type here..."
          onChange={(e) => setContent(e.target.value)}
          value={content}
        />

        <div className="create-post-actions">
          <Upload
            listType="picture-card"
            fileList={fileList}
            multiple
            onPreview={handlePreview}
            onChange={handleChange}
            accept="image/*,video/*"
            beforeUpload={beforeUpload} // Use custom upload handler
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>

          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
          {selectedFiles?.length > 0 && (
            <p className="text-left ml-0">Previous</p>
          )}
          <div className="d-flex gap-3 mb-3">
            {selectedFiles?.map((item, i) => (
              <Image
                width={100}
                src={`https://mh-user-bucket.s3.amazonaws.com/public/users/profile/${item?.url}`}
              />
            ))}
          </div>

          {uploadLoading && (
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Spin size="small" />
            </div>
          )}

          {(content != "" || files.length > 0) && (
            <Button
              className="publish-button p-0 px-4"
              type="primary"
              onClick={() => handleOnSubmitPost()}
              loading={loading}
              disabled={loading && (fileList.length === 0 || content == "")}
            >
              {selectedPost && Object.keys(selectedPost).length > 0
                ? actionLoading
                  ? "Updating"
                  : "Update Now"
                : actionLoading
                ? "Publishing.."
                : "Publish Now"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
